import * as actionTypes from "./actionTypes";

const initialState = {
  loading: false,
  slug: false,
  language: null,
  feathersConnected: false,
  feathersAuthenticated: false,
  feathersUser: {},
  manifest: {
    title: {},
    description: {},
    accessOptions: {},
    plugins: {
      virtualVenues: {}
    }
  },
  alert: {
    visible: false,
    modal: false,
    title: "",
    body: "",
    html: false,
    cancelText: false,
    okText: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SLUG:
      return {
        ...state,
        slug: action.slug,
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE:
      return {
        ...state,
        ...action.app
      };
    case actionTypes.SET_MANIFEST:
      return {
        ...state,
        manifest: action.manifest,
        loading: false
      };
    case actionTypes.SHOW_ALERT:
      return {
        ...state,
        alert: {
          ...action.alert, visible: true
        }
      };
    case actionTypes.HIDE_ALERT:
      return {
        ...state,
        alert: initialState.alert
      };
    default:
      return state;
  }
};
