import React, { useContext, useEffect } from 'react';

// OWN ROOT COMPONENTS
import Meeting from './components/Meeting';

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { getGrouped } from '../../../../data/meetings/selectors';

// OTHER UTILS

// STYLING
const styles = {};

const Component = props => {
  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  useEffect(() => {
    feathersContext.findAndUpdate('meetings', { query: { $sort: { date: 1 }, $limit: 50 } });
  }, [feathersContext]);
console.log("MEETINGS", props.data.meetings.items)
  return (
    <div className="MeetingsGrid">
      <h3>Próximas</h3>
      <div className="Grid">
        {props.data.upcomingMeetings.map( m => <Meeting key={m._id} meeting={m} />)}
      </div>
      <h3>Anteriores</h3>
      <div className="Grid">
        {props.data.pastMeetings.map( m => <Meeting key={m._id} meeting={m} old />)}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    data: {
      meetings: state.data.meetings.items,
      upcomingMeetings: getGrouped().upcoming,
      pastMeetings: getGrouped().past,
    },
    services: {
      app: state.services.app,
    },
  }),
  null
)(Component);