import React, { Fragment, useContext, useState, useEffect } from 'react';

// OWN ROOT COMPONENTS
import TopBar from "../../components/TopBar";
import LeftBar from "../../components/LeftBar";
import Curtain from "../../components/Curtain";
import Profile from "../../components/Profile";
import { scaledImageUrl } from "../../components/ScaledImage";
import RoomsColumn from "./components/RoomsColumn";
import EmbedColumn from "./components/EmbedColumn";
import Counter from './components/Counter';

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";
import { useMixPanel } from '../../services/mixpanel/MixpanelProvider';
import { useIntl, FormattedMessage } from "react-intl";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";

// OTHER UTILS
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";


const HtmlToReactParser = require("html-to-react").Parser;

// STYLING
const styles = {};

const Home = ({ manifest, slug, feathersAuthenticated, user , ...props}) => {
  const intl = useIntl();
  const lang = intl.locale;

  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);
  const { trackMixpanelEvent } = useMixPanel();

  var htmlToReactParser = new HtmlToReactParser();
  var descriptionElement = manifest.plugins.virtualVenues.hall.description ? htmlToReactParser.parse(manifest.plugins.virtualVenues.hall.description[lang]) : null;

  const navigate = useNavigate();

  useEffect(() => {
    trackMixpanelEvent("visitHall")
  }, [trackMixpanelEvent]);



  useEffect(() => {
    if (slug && !manifest.plugins.virtualVenues.hall.active && props.data.rooms.length > 0) {
      const roomSlug = props.data.rooms[0].slug;
      navigate(`/${slug}/${roomSlug}`)
    }
  }, [slug, manifest.plugins.virtualVenues.hall, props.data.rooms, navigate])


  console.log("JKJ")
  return (
    <React.Fragment>
      <TopBar />
      <div className="outmostContainer">
        <LeftBar />
        <div className="outer-container ">
          <Curtain
            manifest={manifest}
            appointments={props.data.appointments}
            rooms={props.data.rooms}
            slug={slug}
            feathersAuthenticated={feathersAuthenticated}
            user={user}
          />
          <div className="central folded">
            <div className="hallHeader">
              <div className="hallInfo Module">
                <div className="Header">
                  <div className="Left">
                    <h2>{manifest.title && manifest.title[lang]}</h2>
                    {manifest.eventDate ? (
                      <span className="Date">
                        <Moment filter={(t) => (t.charAt(0).toUpperCase() + t.slice(1)) } locale={lang} format="LLLL">
                          {manifest.eventDate}
                        </Moment>
                      </span>
                    ) : null}
                  </div>
                  {manifest.eventDate ?
                    <Counter date={manifest.eventDate} />
                  : null}
                </div>

                <p>{descriptionElement}</p>
              </div>
              <div
                className="hallBanner Module"
                style={{
                  backgroundColor: manifest.themeColor,
                  backgroundImage: `url(${manifest.logo})`,
                  //backgroundImage: `url(/test.png)`
                }}
              >
              </div>
            </div>

            <div className="hallColumns">
              <EmbedColumn
                flex={2}
                embedType={manifest.plugins.virtualVenues.hall.embedType}
                embedRef={manifest.plugins.virtualVenues.hall.embedRef ? manifest.plugins.virtualVenues.hall.embedRef[lang] : null}
                description={manifest.plugins.virtualVenues.hall.embedDescription ? manifest.plugins.virtualVenues.hall.embedDescription[lang] : null}
              />
              {manifest.plugins.virtualVenues.hall.showRooms !== false && <RoomsColumn flex={1} rooms={props.data.rooms} manifest={manifest} /> }
            </div>
          </div>
          <Profile />
        </div>
      </div>
    </React.Fragment>
  );
}

export default connect(
  (state) => ({
    data: {
      appointments: state.data.appointments.items,
      rooms: state.data.rooms.items,
    },
    apploading: state.services.app.loading,
    manifest: state.services.app.manifest,
    slug: state.services.app.slug,
    feathersAuthenticated: state.services.app.feathersAuthenticated,
    user: state.services.app.feathersUser,
  }),
  null
)(Home);