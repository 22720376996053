import React, { Fragment, useState, useEffect, useContext } from "react";

// OWN ROOT COMPONENTS
import ScaledImage from '../ScaledImage';
import PlaceholderAvatar from '../PlaceholderAvatar';
import { foldUnfoldProfile } from '../TopBar';

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";

// OTHER UTILS
import { useIntl, FormattedMessage } from "react-intl";
import axios from "axios";
import apiConfig from "../../services/api/config";

// STYLING
const styles = {};




const Profile = (props) => {
  const intl = useIntl();

  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageRef, setImageRef] = useState(null);

  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    subtitle: '',
    picture: '',
    bio: ''
  });

  useEffect(() => {
    setUser({
      firstName: props.services.app.feathersUser.firstName,
      lastName: props.services.app.feathersUser.lastName,
      subtitle: props.services.app.feathersUser.subtitle,
      email: props.services.app.feathersUser.email,
      picture: props.services.app.feathersUser.picture,
      bio: props.services.app.feathersUser.bio
    });
  }, [props.services.app.feathersUser])




  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = async event => {
    const fileUploaded = event.target.files[0];

    let url = "";

    const formData = new FormData();
    formData.append("uri", fileUploaded);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        setProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    };

    const fileType = fileUploaded.type;

    let imageKey = '';

    async function getHash(blob, algo = "SHA-256") {
      // convert your Blob to an ArrayBuffer
      // could also use a FileRedaer for this for browsers that don't support Response API
      const buf = await new Response(blob).arrayBuffer();
      const hash = await crypto.subtle.digest(algo, buf);
      let result = '';
      const view = new DataView(hash);
      for (let i = 0; i < hash.byteLength; i += 4) {
        result += view.getUint32(i).toString(16).padStart(2, '0');
      }
      return result;
    }

    if (fileType.includes("image")) { // if its s3
      const fileExt = fileUploaded.name.split('.').pop();
      const hash = await getHash(fileUploaded)
      const result = await feathersContext
        .create("directuploads", {
          //key: event.target.files[0].name
          key: `${hash}.${fileExt}`,
          type: fileType,
          width: 100,
          height: 100
        })
      url = result.signedUrl;
      imageKey = result.key;
      config.headers['content-type'] = fileType

      axios({
        method: 'put',
        url,
        data: fileUploaded,
        config
      })
        .then((ar) => {

          setImageRef(imageKey);
          setUser(u => ({ ...u, picture: result.signedResizedURL }));

          setLoading(false);
        })
        .catch((e) => {
          console.log("Axios error", e.response);
          setLoading(false);
        });
    }
  };

  const setField = (e, field) => {
    e.persist();
    setUser(u => ({ ...u, [field]: e.target.value}));
  }

  const submit = (e) => {
    //alert(JSON.stringify(user));
    feathersContext.updateUser(props.services.app.feathersUser._id, user).then(r => {
      alert("Perfil actualizado");
      foldUnfoldProfile(e);
    });
  }

  return (
    <div className={`Module Profile ${props.className}`} >
      <h2><FormattedMessage id="editProfile.editUser" /></h2>
      <div className="Fields">
        <div className="Field">
          <span className="Label"><FormattedMessage id="editProfile.picture" /></span>
          <div className="ProfilePic">
            {user.picture ? (
              <ScaledImage image={user.picture} className="Avatar" />
            ) : (
              <PlaceholderAvatar
                user={user}
                themeColor={props.services.app.manifest.themeColor}
                className="Avatar"
              />
            )}
            <button  onClick={handleClick} className="btn btn-outline btn-block"><FormattedMessage id="editProfile.upload" /></button>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{display: 'none'}}
            />
          </div>
        </div>
        <div className="Field">
          <span className="Label"><FormattedMessage id="editProfile.firstName" /></span>
          <input placeholder={intl.formatMessage({ id: "editProfile.firstName" })} value={user.firstName} onChange={e => setField(e, 'firstName')} />
        </div>
        <div className="Field">
          <span className="Label"><FormattedMessage id="editProfile.lastName" /></span>
          <input placeholder={intl.formatMessage({ id: "editProfile.lastName" })} value={user.lastName} onChange={e => setField(e, 'lastName')} />
        </div>
        <div className="Field">
          <span className="Label"><FormattedMessage id="editProfile.subtitle" /></span>
          <input placeholder={intl.formatMessage({ id: "editProfile.subtitle" })} value={user.subtitle} onChange={e => setField(e, 'subtitle')} />
        </div>
        <div className="Field">
          <span className="Label"><FormattedMessage id="editProfile.email" /></span>
          <input placeholder={intl.formatMessage({ id: "editProfile.email" })} value={user.email} onChange={e => setField(e, 'email')} />
        </div>
        <div className="Field">
          <span className="Label"><FormattedMessage id="editProfile.bio" /></span>
          <textarea rows={4} placeholder={intl.formatMessage({ id: "editProfile.bio" })} value={user.bio} onChange={e => setField(e, 'bio')}></textarea>
        </div>
        <button onClick={submit} className="btn btn-block"><FormattedMessage id="editProfile.save" /></button>
      </div>
    </div>
  );
};


export default connect(
  (state) => ({
    services: {
      app: state.services.app,
    },
  }),
  null
)(Profile);