import React, { useState, useEffect, useContext } from "react";

import WidgetLoader from "../../../WidgetLoader";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as surveyBlocksActionCreators from "../../../../data/surveyBlocks/actions";

import { FeathersContext } from "../../../../services/feathers/FeathersProvider";

import { useIntl, FormattedMessage } from "react-intl";


function SurveyBlocksWidget(props) {
  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  const intl = useIntl();

  const { moduleId, socket, themeColor } = props;

  const [voted, setVoted] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [option, setOption] = useState(false);

  useEffect(() => {
    if (props.feathersUser._id && props.surveyBlock.questions && props.surveyBlock.questions.length > 0) {
      feathersContext.client.service("votes").find({ query: { userId: props.feathersUser._id, questionId: props.surveyBlock.questions[0]._id } })
        .then( r => {
          setVoted(r.total > 0)
        } );
    }
  }, [feathersContext.client, props.feathersUser._id, props.moduleId, props.surveyBlock.questions]);

  const lang = intl.locale;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (option) {
      setSubmitting(true);
      console.log(option);
      feathersContext.create("votes", {
        moduleId: props.moduleId,
        questionId: option.surveyId,
        optionId: option.surveyOptionId,
      })
        .then(() => {
          setSubmitting(false);
          //setOption(false);
          setVoted(true);
        })
        .catch((e) => {
          setSubmitting(false);
          alert(JSON.stringify(e))
        });
      // surveyBlocksApi.vote({
      //   votes: [
          // {
          //   survey_id: option.surveyId,
          //   survey_option_id: option.surveyOptionId,
          // },
      //   ],
      // })
        // .then(() => {
        //   setSubmitting(false);
        //   //setOption(false);
        //   setVoted(true);
        // })
        // .catch((e) => {
        //   setSubmitting(false);
        // });
    }
  };

  const renderSurvey = () => {
    if (props.surveyBlock.questions.length > 0) {
      const s = props.surveyBlock.questions[0];
      return voted ? (
        <div class="survey-block voted">
          <h4>{s.title && s.title[lang]}</h4>
          <p>
            <span>
              <FormattedMessage id="surveyBlocksWidget.yourAnswer" />
              {": "}
            </span>
            {option.optionText}
          </p>
        </div>
      ) : (
        <form class="survey-block pill" onSubmit={handleSubmit}>
          <h4>{s.title && s.title[lang]}</h4>

          <div class="survey-options-wrap">
            <ul class="survey-options">
              {s.options.map((so) => {
                return (
                  <li class={`option ${submitting ? "disabled" : null}`}>
                    <input
                      name={`s-${s._id}`}
                      id={`so-${so._id}`}
                      value={so._id}
                      checked={option.surveyOptionId === so._id}
                      type="radio"
                      disabled={submitting}
                      onChange={(e) =>
                        setOption({
                            surveyId: s._id,
                            surveyOptionId: e.currentTarget.value,
                            optionText: so.title[lang],
                          })
                      }
                    />
                    <label for={`so-${so._id}`}>{so.title && so.title[lang]}</label>
                  </li>
                );
              })}
            </ul>
            <button class="btn" type="submit" disabled={submitting || !option}>
              {submitting
                ? intl.formatMessage({ id: "surveyBlocksWidget.submitting" })
                : intl.formatMessage({ id: "surveyBlocksWidget.submit" })}
            </button>
          </div>
        </form>
      );
    } else {
      return <div></div>
    }
  }

  return (
    props.loading ? (
      <WidgetLoader themeColor={themeColor} />
    ) : renderSurvey()
  );
}

export default connect(
  (state, ownProps) => ({
    manifest: state.services.app.manifest,
    loading: false,
    surveyBlocks: state.data.surveyBlocks.modules[ownProps.moduleId]
      ? state.data.surveyBlocks.modules[ownProps.moduleId].items
      : [],
    feathersUser: state.services.app.feathersUser
  }),
  (dispatch) => ({
    actions: {
      surveyBlocks: bindActionCreators(surveyBlocksActionCreators, dispatch),
    },
  })
)(SurveyBlocksWidget);