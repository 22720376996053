import React, { useContext, useCallback, useEffect, useState } from "react";

// OWN ROOT COMPONENTS

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { getGrouped } from "../../data/users/selectors";
import ScaledImage from "../../components/ScaledImage";
import PlaceholderAvatar from "../../components/PlaceholderAvatar";

// OTHER UTILS


const UsersColumn = ({ ...props }) => {

  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  const [query, setQuery] = useState("");

  const initialLoad = useCallback(() => {
    feathersContext
      .findAndUpdate("users", {
        query: {
          $sort: {
            lastName: 1,
          },
        },
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }, [feathersContext]);

  useEffect(() => {
    initialLoad()
  }, [initialLoad]);

  const loadMore = useCallback(
    (info) => {
      const items = props.data.users.items;
      if (items.length < props.data.users.total) {
        feathersContext
          .findAndAppend("users", {
            query: {
              ...(query != ""
                ? {
                    invisible: false,
                    $or: [
                      { firstName: { $like: query, $search: query } },
                      { lastName: { $like: query, $search: query } },
                      { subtitle: { $like: query, $search: query } },
                    ],
                  }
                : { invisible: false }),
              $skip: items.length,
              $sort: {
                lastName: 1,
              },
            },
          })
          .catch((e) => {
            console.log("Error", e);
          });
      }
    },
    [feathersContext, props.data.users.items, props.data.users.total, query]
  );

  const handleSearch = useCallback((t) => {
    setQuery(t);
    feathersContext
      .findAndUpdate("users", {
        query: {
          ...(t != ""
            ? {
                invisible: false,
                $or: [
                  { firstName: { $like: t, $search: t } },
                  { lastName: { $like: t, $search: t } },
                  { subtitle: { $like: t, $search: t } },
                ],
              }
            : { invisible: false }),
          $sort: {
            lastName: 1,
          },
        },
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }, [feathersContext]);

  return (
    <div className={`Module Users ${props.className}`}>
      {!props.omitHeader &&
        <div className="Header">
          <span class="icon-md-template"></span>
          <h3>Usuarios</h3>
        </div>
      }
      {props.customHeader ? props.customHeader() : null}
      <div className="Search">
        <input placeholder="Buscar..." onChange={(e) => handleSearch( e.target.value )} val={query} />
        <p>Perfiles de búsqueda por sectores: Administración Portuaria · Agente de aduanas · Asociación empresarial · Consignatorio · Formación · Importador / Exportador · Institución pública · Naviera / Armador · Terminal / Empresa estibadora · TIC / Consultoría · Transitario / Operador logístico · Otros</p>
      </div>
      {props.data.users.groups.map((g) => (
        <div className="Initial">
          <h4>{g.title}</h4>
          {g.data.map((user) => (
            <div onClick={() => props.userClicked(user)} className="User">
              {user.picture ? (
                <ScaledImage image={user.picture} className="Avatar" />
              ) : (
                <PlaceholderAvatar
                  user={user}
                  themeColor={props.services.app.manifest.themeColor}
                  className="Avatar"
                />
              )}
              <div className="UserText">
                <h5>
                  {user.firstName} {user.lastName}
                </h5>
                <span>{user.subtitle}</span>
              </div>
            </div>
          ))}
        </div>
      ))}
      {props.data.users.items.length < props.data.users.total &&
        <button onClick={loadMore} className="btn btn-outline LoadMore">Cargar más</button>
      }
    </div>
  );
};

export default connect(
  (state) => ({
    data: {
      users: {
        ...state.data.users,
        groups: getGrouped(),
      },
    },
    services: {
      app: state.services.app
    }
  }),
  null
)(UsersColumn);