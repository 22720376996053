import * as actionTypes from "./actionTypes";
import messagesReducer from "../messages/reducer";

const initialState = {
  loading: false,
  unread: 0,
  items: [],
  total: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE:
      return {
        ...state,
        loading: false,
        items: action.items,
        total: action.total,
        unread: action.unread,
      };
    case actionTypes.APPEND:
      return {
        ...state,
        loading: false,
        items: [...state.items, ...action.items],
        total: action.total,
        unread: action.unread,
      };
    case actionTypes.UPDATE_ONE:
      return {
        ...state,
        loading: false,
        items: state.items.map((i) => {
          if (i._id == action.item._id) {
            return action.item;
          } else {
            return i;
          }
        }),
      };
    case actionTypes.EMPTY:
      return initialState;
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
