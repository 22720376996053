import React, { useContext, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../../services/app/actions";

import { useNavigate } from "react-router-dom";

import ScaledImage from '../ScaledImage';

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";

import { Link } from "react-router-dom";

import { useIntl, FormattedMessage } from "react-intl";
import $ from "jquery";



export const foldUnfoldProfile = (e, what = false) => {
  e.preventDefault();
  if (!$(".Profile").hasClass("leftUnfolded")) {
    $(".central, .Profile, .sidebar").addClass("leftUnfolded");
  } else {
    if (!what || $(`.inner.${what}`).is(":visible")) {
      $(".central, .Profile, .sidebar").removeClass("leftUnfolded");
    }
  }

  if (what) {
    $(".inner").hide();
    $(`.inner.${what}`).show();
  }
};

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? "active" : "",
      };
    }}
  />
);

const LangPicker = (props) => {

  const intl = useIntl();

  const [langActive, setLangActive] = useState(false);

  const languageNames = new Intl.DisplayNames([intl.locale], {type: 'language'});

  return (
    <React.Fragment>
      {langActive ? <div onClick={() => setLangActive(false)} style={{ position: 'fixed', zIndex: 199, width: '100%', height: '100%', left: 0, bottom: 0 }}></div> : null }
      <div className="LangPicker">
        <div className="ActiveLanguage" onClick={() => setLangActive(!langActive)}>
          <span className="icon-md-globe-alt"></span> {intl.locale.toUpperCase()}
        </div>
        <ul className={`Languages ${langActive ? 'Active' : ''}`}>
          {props.manifest.langPattern.available.map(l => (
            <li onClick={() => props.setLang(l)}>
              <span className="Indicator">
                {intl.locale == l ? <span className="Fill"></span> : null}
              </span>
              {capitalizeFirstLetter(languageNames.of(l))} - {l.toUpperCase()}</li>
          ))}
        </ul>

      </div>
    </React.Fragment>

  );
}

const TopBar = (props) => {
  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <div className="top-bar">
      <div className="left">
        <div className="logo">
          <ScaledImage image={props.manifest.icon} width={64} height={64} fit='cover' />
        </div>
        <h1>{props.manifest.title && props.manifest.title[intl.locale]}</h1>
      </div>
      {props.feathersAuthenticated ? (
        <div className="right">
          <span class="basic-thumb" onClick={(e) => foldUnfoldProfile(e)}>
            {props.user._id && props.user.firstName.substr(0, 1)}
            {props.user._id && props.user.lastName.substr(0, 1)}
          </span>
          <a href="#" onClick={() => feathersContext.logOut()}>
            <FormattedMessage id="topBar.logout" />
          </a>
          <LangPicker manifest={props.manifest} setLang={language => props.actions.app.update({ language })} />
        </div>
      ) : (
        <div className="right">
          {props.manifest.accessOptions.loginable && (
            <Link to={`/${props.slug}/signin`}>
              <FormattedMessage id="topBar.login" />
            </Link>
          )}
          {props.manifest.accessOptions.registerable && !props.manifest.accessOptions.registerOverride && (
            <Link to={props.manifest.accessOptions.registerOverride ? props.manifest.accessOptions.registerURL.es : `/${props.slug}/signup`}>
              <FormattedMessage id="topBar.signUp" />
            </Link>
          )}
          {props.manifest.accessOptions.registerOverride && (
            <a href={props.manifest.accessOptions.registerURL[intl.locale]}>
              <FormattedMessage id="topBar.signUp" />
            </a>
          )}
          <LangPicker manifest={props.manifest} setLang={language => props.actions.app.update({ language })} />
        </div>
      )}
    </div>
  );

};

export default connect(
  (state, ownProps) => ({
    loading: state.services.app.loading,
    manifest: state.services.app.manifest,
    slug: state.services.app.slug,
    feathersAuthenticated: state.services.app.feathersAuthenticated,
    user: state.services.app.feathersUser,
  }),
  (dispatch) => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch),
    },
  })
)(TopBar);
