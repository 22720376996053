import React, { useState, useContext, Fragment } from 'react';

// OWN ROOT COMPONENTS
import TopBar from '../../components/TopBar';
import LeftBar from '../../components/LeftBar';
import Curtain from '../../components/Curtain';
import BelongModal from '../../components/BelongModal';
import UserList from '../../components/UserList';
import PlaceholderAvatar from '../../components/PlaceholderAvatar';
import Profile from '../../components/Profile';
import ScaledImage from '../../components/ScaledImage';
import MeetingGrid from './components/MeetingGrid';


// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// OTHER UTILS
import { useIntl, FormattedMessage } from "react-intl";
import moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('es', es)

// STYLING
const styles = {};

const Meetings = props => {
  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  const [modalVisible, setModalVisible] = useState(false);
  const [meeting, setMeeting] = useState({});
  const [users, setUsers] = useState([]);
  const [rightBarUser, setRightBarUser] = useState(null);

  const intl = useIntl();

  const searchUser = query => {
    if (query !== "") {
      feathersContext.find("users", { query: {
                    $or: [
                      { firstName: { $like: query, $search: query } },
                      { lastName: { $like: query, $search: query } },
                      { subtitle: { $like: query, $search: query } },
                    ],
                  } }).then( r => {
                    setUsers(r.data);
                  })
    } else {
      setUsers([]);
    }

  }

  const addParticipant = user => {
    const newMeeting = { ...meeting };
    if (!newMeeting.participants) newMeeting.participants = [];
    newMeeting.participants.push({
      userId: user._id,
      ...user,
      isStaff: false,
      attending: 'pending'
    });
    setMeeting(newMeeting);
    const input = document.getElementById("participants");
    if (input) {
      input.value = "";
    }

  }

  const removeParticipant = user => {
    const newMeeting = { ...meeting };
    newMeeting.participants = newMeeting.participants.filter( p => p.userId !== user._id);
    setMeeting(newMeeting);
  }

  const submitMeeting = () => {
    if ( meeting.participants && meeting.participants.length > 0 && meeting.title && meeting.date ) {
      const newMeeting = {
        ...meeting,
        participants: [ ...meeting.participants||[], { userId: props.services.app.feathersUser._id, isStaff: true, attending: 'yes' } ],
        date: moment(meeting.date, 'dd/MM/yyyy HH:mm').format()
      }
      //alert(JSON.stringify(newMeeting));
      feathersContext.create("meetings", newMeeting).then( r => {
        setMeeting({});
        setModalVisible(false);
      })
    }

  }

  return (
    <Fragment>
      <TopBar />
      <div className="outmostContainer">
        <LeftBar slug={props.services.app.slug} />
        <div className="outer-container">
          <Curtain
            manifest={props.services.app.manifest}
            appointments={props.data.appointments}
            rooms={props.data.rooms}
            slug={props.services.app.slug}
            feathersAuthenticated={props.services.app.feathersAuthenticated}
          />
          <div className="central folded">
            <div className="CentralModule MeetingsModule Module">
              <div className="MeetingsHeader">
                <h2>Reuniones</h2>
                <button onClick={() => setModalVisible(true)} className="btn">Añadir reunión</button>
              </div>
              <MeetingGrid />
            </div>
          </div>
          {rightBarUser ?
            <div className="Module MeetingsUser sidebar folded">
              <div className="UserInfo">
                {rightBarUser.picture ? (
                  <ScaledImage image={rightBarUser.picture} className="Avatar" />
                ) : (
                  <PlaceholderAvatar
                    user={rightBarUser}
                    themeColor={props.services.app.manifest.themeColor}
                    className="Avatar"
                  />
                )}
                <h2 className="Name">{rightBarUser.firstName} {rightBarUser.lastName}</h2>
                <h3 className="Subtitle">{rightBarUser.subtitle}</h3>
              </div>

              <button onClick={() => {
                setModalVisible(true);
                addParticipant(rightBarUser);
              }} className="btn">Solicitar reunión</button>
              <button onClick={() => {
                setRightBarUser(null)
              }} className="btn btn-outline">Volver</button>
              <h4>Email</h4>
              <p>{rightBarUser.email}</p>
              <h4>Biografía</h4>
              <p>{rightBarUser.bio}</p>
            </div>
          :
            <UserList userClicked={(user) => setRightBarUser(user)} className="MeetingsUsers sidebar folded" />
          }
          <Profile />
        </div>
      </div>
      <BelongModal
        visible={modalVisible}
        header={
          <React.Fragment>
            <h2>Nueva reunión</h2>
          </React.Fragment>
        }
        body={
          <div className="Meeting">
            <div className="Attribute">
              <div className="Key">
                <span className="icon-md-book-open"></span>
                <span className="Label">Título</span>
              </div>
              <div className="Value">
                <input
                  type="text"
                  placeholder="Título de la reunión"
                  onChange={(e) => {
                    e.persist();
                    setMeeting( m => ({ ...m, title: e.target.value }) )
                  }}
                />
              </div>
            </div>
            <div className="Attribute">
              <div className="Key">
                <span className="icon-md-users"></span>
                <span className="Label">Participantes</span>
              </div>
              <div className="Value">
                {meeting.participants && meeting.participants.map( u => (
                  <div className="User">
                    {u.picture ? (
                      <ScaledImage image={u.picture} className="Avatar" />
                    ) : (
                      <PlaceholderAvatar
                        user={u}
                        themeColor={props.services.app.manifest.themeColor}
                        className="Avatar"
                      />
                    )}
                    <span className="Name">{u.firstName} {u.lastName}</span>
                    <button onClick={() => removeParticipant(u)}><span className="icon-md-x" /></button>
                  </div>
                ))}
                <input
                  type="text"
                  placeholder="Añade más participantes"
                  id="participants"
                  onChange={(e) => searchUser( e.target.value )}
                  onBlur={() => searchUser("") }
                  onFocus={(e) => searchUser(e.target.value) }
                />
                <div className="UsersResult">
                  {users.map( u => (
                    <div className="User" onMouseDown={() => addParticipant(u) }>
                      {u.picture ? (
                        <ScaledImage image={u.picture} className="Avatar" />
                      ) : (
                        <PlaceholderAvatar
                          user={u}
                          themeColor={props.services.app.manifest.themeColor}
                          className="Avatar"
                        />
                      )}
                      <span>{u.firstName} {u.lastName}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="Attribute">
              <div className="Key">
                <span className="icon-md-clock"></span>
                <span className="Label">Fecha</span>
              </div>
              <div className="Value">
                <DatePicker
                  selected={meeting.date}
                  onChange={date => setMeeting( m => ({ ...m, date: date }) )}
                  locale="es"
                  minDate={new Date()}
                  showTimeInput
                  timeInputLabel="Hora:"
                  dateFormat="dd/MM/yyyy HH:mm z"
                  placeholderText="Elige una fecha"
                />
              </div>
            </div>
          </div>
        }
        footer={
          <React.Fragment>
            <button onClick={() => {
              setMeeting({});
              setModalVisible(false);
            }} className="btn btn-outline">Cancelar</button>
            <button onClick={() => submitMeeting() } className="btn">Solicitar reunión</button>
          </React.Fragment>
        }
      />
    </Fragment>
  );
}

export default connect(
  (state) => ({
    data: {
      appointments: state.data.appointments.items,
      rooms: state.data.rooms.items
    },
    services: {
      app: state.services.app,
    },
  }),
  null
)(Meetings);