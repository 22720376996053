import React, { useEffect, useState, useContext, Fragment } from "react";

import TopBar from "../../components/TopBar";
import { scaledImageUrl } from '../../components/ScaledImage'

import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../../services/app/actions";

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const amplitude = require("amplitude-js");

function App(props) {
  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);
  const intl = useIntl();
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [token, setToken] = useState(false);
  const [origin, setOrigin] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);

  const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  useEffect(() => {
    amplitude.getInstance().logEvent("pageView", { name: "SignIn" });
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setToken(urlParams.get('token'));
    setOrigin(urlParams.get('origin'));
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--theme-primary-color",
      props.manifest.themeColor
    );
    const components = hexToRgb(props.manifest.themeColor);
    if (components) {
      document.documentElement.style.setProperty(
        "--theme-primary-color-light",
        `rgba(${components.r},${components.g},${components.b},0.1)`
      );
      document.documentElement.style.setProperty(
        "--theme-primary-color-hover",
        `rgba(${components.r},${components.g},${components.b},0.85)`
      );
    }
  }, [props.manifest]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password && password != '') {
      feathersContext.client.service("authManagement").create({
        action: "resetPwdLong",
        value: {
          token, // compares to .resetToken
          password, // new password
        }
      }).then(r => {
        if (origin == 'virtualVenues') {
          navigate(`/${props.slug}/signin`)
          props.actions.app.showAlert({
            modal: true,
            body: intl.formatMessage({ id: "resetPassword.changed" }),
            okText: intl.formatMessage({ id: "resetPassword.alertClose" })
          });
        } else {
          setPasswordChanged(true);
          props.actions.app.showAlert({
            modal: true,
            body: intl.formatMessage({ id: "resetPassword.changedApp" }),
            okText: intl.formatMessage({ id: "resetPassword.alertClose" })
          });
        }

      }).catch(e => {
        props.actions.app.showAlert({
          modal: true,
          body: intl.formatMessage({ id: "resetPassword.invalid" }),
          okText: intl.formatMessage({ id: "resetPassword.alertClose" })
        });
      })
    } else {
      props.actions.app.showAlert({
        modal: true,
        body: intl.formatMessage({ id: "resetPassword.passwordRequired" }),
        okText: intl.formatMessage({ id: "resetPassword.alertClose" })
      });
    }
  };


  const { manifest, slug } = props;

  const lang = intl.locale;

  return (
    props.manifest.accessOptions.loginable && (
      <div
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column", backgroundColor: props.manifest.themeColor }}
      >
        {origin == 'virtualVenues' && <TopBar /> }
        <div className="empty-container" style={{  }}>
          <div className="login">
            <div
              className="logo"
              style={{
                backgroundImage: `url(${props.manifest.logo})`,
              }}
            ></div>
            <h2>
              <FormattedMessage id="resetPassword.title" />
            </h2>
            <h3>{manifest.title && manifest.title[lang]}</h3>
            {!passwordChanged &&
              <form>
                <div className="pill login-form">
                  <input
                    type="password"
                    placeholder={intl.formatMessage({ id: "resetPassword.password" })}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                {error && !submitting && (
                  <div className="message-box show">{error}</div>
                )}

                <button
                  disabled={submitting}
                  onClick={handleSubmit}
                  className="btn btn-block btn-lg"
                >
                  {submitting
                    ? intl.formatMessage({ id: "resetPassword.submitting" })
                    : intl.formatMessage({ id: "resetPassword.submit" })}
                </button>
                {origin == 'virtualVenues' && props.manifest.accessOptions.registerable && (
                  <p className="help">
                    <FormattedMessage id="resetPassword.accountAlready" />{" "}
                    <Link to={`/${props.slug}/signin`}>
                      <FormattedMessage id="resetPassword.logIn" />
                    </Link>
                  </p>
                )}
              </form>
            }
          </div>
        </div>
      </div>
    )
  );
}

export default connect(
  (state, ownProps) => ({
    manifest: state.services.app.manifest,
    slug: state.services.app.slug,
    feathersAuthenticated: state.services.app.feathersAuthenticated,
  }),
  (dispatch) => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch),
    },
  })
)(App);
