import * as actionTypes from "./actionTypes";

const initialState = {
  loading: false,
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE:
      return {
        ...state,
        loading: false,
        items: action.items,
      };
    case actionTypes.APPEND:
      return {
        ...state,
        loading: false,
        items: [...state.items, ...action.items],
      };
    case actionTypes.UPDATE_ONE:
      return {
        ...state,
        loading: false,
        items: state.items.map((item) =>
          item._id === action.item._id ? action.item : item
        ),
      };
    case actionTypes.DESTROY:
      return {
        ...state,
        loading: false,
        items: state.items.filter((item) => item._id !== action.item._id),
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
