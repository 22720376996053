import React, { useEffect, useState, useContext } from 'react';

import BelongModal from '../../../../components/BelongModal';
import ScaledImage from '../../../../components/ScaledImage';
import PlaceholderAvatar from '../../../../components/PlaceholderAvatar';
import Meeting from './components/Meeting';

import { useIntl, FormattedMessage } from "react-intl";

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";
import { connect } from "react-redux";
import moment from "moment";


const MeetingsGrid = props => {
  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);


  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    feathersContext.findAndUpdate('meetings', { query: { $sort: { date: 1 }, $limit: 50 } });

    feathersContext.find("rooms", {
      query: {
        $limit: -1
      }
    }).then( r => {
      setRooms(r);
    } );
  }, [feathersContext]);



  const acceptMeeting = meeting => {
    feathersContext.patchAndUpdate("meetings", meeting._id, { $set: {"participants.$.attending": "yes"} }, { query: { _id: meeting._id, "participants.userId": props.services.app.feathersUser._id } });
  };

  const declineMeeting = meeting => {
    feathersContext.patchAndUpdate("meetings", meeting._id, { $set: {"participants.$.attending": "no"} }, { query: { _id: meeting._id, "participants.userId": props.services.app.feathersUser._id } });
  };

  const deleteMeeting = meeting => {
    if (window.confirm("Seguro?")) {
      feathersContext.removeAndUpdate("meetings", meeting._id);
    }

  };


  const mixed = (props.meetingsFilter == 'active' ?
      // [...props.data.appointments, ...props.data.meetings.filter(m => m.participants.filter(p => p.attending == 'no').length == 0)].sort((a,b) => {
      //   return (a.startDate||a.date) > (b.startDate||b.date) ? 1 : -1;
      // })
      [...props.data.meetings.filter(m => m.participants.filter(p => p.attending == 'no').length == 0)].sort((a,b) => {
        return (a.startDate||a.date) > (b.startDate||b.date) ? 1 : -1;
      })
    :
      props.data.meetings.filter(m => m.participants.filter(p => p.attending == 'no').length != 0).sort((a,b) => a.date > b.date ? 1 : -1)).filter(a => {
        return true;
        return moment(a.startDate||a.date) > (moment() - 86400000); // CLOSER THAN 1 DAY AGO
      });


  const intl = useIntl();
  const lang = intl.locale;

  let prevHour = moment('1970-01-01T00:00:00');
  let prevDay = moment('1970-01-01T00:00:00');

  const renderDay = (ap) => {
    if (!prevDay.isSame(ap.startDate||ap.date, 'day')){
      prevDay = moment(ap.startDate||ap.date);
      return <div className="Day">{prevDay.locale(lang).format('dddd D')}</div>;
    }
  }

  const renderHour = (ap) => {
    if (!prevHour.isSame(ap.startDate||ap.date, 'hour')){
      prevHour = moment(ap.startDate||ap.date);
      return <div className="Hour">{prevHour.locale(lang).format('HH:00')}</div>;
    }
  }

  return (
    <div className="Meetings">
      <div className="Header">
        <div className="Left">
          <span class="icon-md-calendar"></span>
          <h3><FormattedMessage id="networking.agenda.title" /> {}</h3>
        </div>
        {/*<a href="#" onClick={props.newMeeting}>+ Añadir reunión</a>*/}
      </div>
      <div className="Body">
        {mixed.length == 0 &&
          <div className="Empty">
            <span className="icon-md-calendar"></span>
            <p><FormattedMessage id="networking.agenda.hint" /></p>
            <button className="btn btn-outline" onClick={props.goToUsers}><FormattedMessage id="networking.agenda.goToUsers" /></button>
          </div>
        }
        {mixed.map(ap => {
          return (
            <React.Fragment>
              {renderDay(ap)}
              {renderHour(ap)}
              <Meeting
                key={ap._id}
                appointment={ap}
                rooms={rooms}
                lang={lang}
                userId={props.services.app.feathersUser._id}
                acceptMeeting={acceptMeeting}
                declineMeeting={declineMeeting}
                deleteMeeting={deleteMeeting}
                editMeeting={props.editMeeting}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    data: {
      appointments: state.data.appointments.items,
      meetings: state.data.meetings.items,
    },
    services: {
      app: state.services.app,
    },
  }),
  null
)(MeetingsGrid);