import React, { useState, useContext, Fragment } from 'react';

// OWN ROOT COMPONENTS
import TopBar from '../../components/TopBar';
import LeftBar from '../../components/LeftBar';
import Curtain from '../../components/Curtain';
import Profile from '../../components/Profile';
import BelongModal from '../../components/BelongModal';
import UserList from '../../components/UserList';
import PlaceholderAvatar from '../../components/PlaceholderAvatar';
import ScaledImage from '../../components/ScaledImage';
import Conversations from './components/Conversations';
import Conversation from './components/Conversation';


// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as messagesActionCreators from "../../data/messages/actions";
import { Link } from "react-router-dom";

// OTHER UTILS
import { useIntl, FormattedMessage } from "react-intl";
import moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('es', es)

// STYLING
const styles = {};

const Chat = props => {
  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  const [selectedUser, setSelectedUser] = useState(null);

  const [activeModule, setActiveModule] = useState("conversations");


  const intl = useIntl();

  const sidebarHeader = () => {
    return (
      <div className="Header Selector">
        <h3 className={`WithClick ${activeModule === 'conversations' && 'Active'}`} onClick={() => setActiveModule("conversations")}>Chats</h3>
        <h3 className={`WithClick ${activeModule === 'users' && 'Active'}`} onClick={() => setActiveModule("users")}>Usuarios</h3>
      </div>
    );
  }

  return (
    <Fragment>
      <TopBar />
      <div className="outmostContainer">
        <LeftBar slug={props.services.app.slug} />
        <div className="outer-container FixedHeight">
          <Curtain
            manifest={props.services.app.manifest}
            appointments={props.data.appointments}
            rooms={props.data.rooms}
            slug={props.services.app.slug}
            feathersAuthenticated={props.services.app.feathersAuthenticated}
          />
          {activeModule === "conversations" ?
            <Conversations
              activeConversation={selectedUser}
              omitHeader
              customHeader={sidebarHeader}
              className="MeetingsUsers sidebar folded"
              conversationClicked={(c) => {
                if (c.counterpartUser !== selectedUser) {
                  props.actions.messages.empty();
                  props.actions.messages.loading();
                  props.actions.messages.setCurrentConversation(c.counterpartUser._id);
                  setSelectedUser(c.counterpartUser);
                  feathersContext.readConversation(c.counterpartUser._id);
                }

              }}

            />
            :
            <UserList
              userClicked={(user) => {
                props.actions.messages.empty();
                props.actions.messages.loading();
                props.actions.messages.setCurrentConversation(user._id);
                setSelectedUser(user);
                feathersContext.readConversation(user._id);
              }}
              omitHeader
              customHeader={sidebarHeader}
              className="MeetingsUsers sidebar folded"
            />
          }



          <div className="central folded">
            <div className="CentralModule ChatModule Module">
              {selectedUser ?
                  <Conversation sender={selectedUser} />
                :
                <div className="Placeholder">
                  <span className="icon-md-user-circle"></span>
                  <span>Selecciona una conversación</span>
                </div>
              }
            </div>
          </div>
          <Profile />
        </div>
      </div>
    </Fragment>
  );
}

export default connect(
  (state) => ({
    data: {
      appointments: state.data.appointments.items,
      rooms: state.data.rooms.items
    },
    services: {
      app: state.services.app,
    },
  }),
  (dispatch) => ({
    actions: {
      messages: bindActionCreators(messagesActionCreators, dispatch),
    },
  })
)(Chat);