import React, { useContext, useState } from 'react';

// OWN ROOT COMPONENTS
import BelongModal from '../../../../../../components/BelongModal';

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../../../services/feathers/FeathersProvider";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";

// OTHER UTILS
import moment from 'moment';
import Moment from 'react-moment';
import AddToCalendar from 'react-add-to-calendar';
import AddToCalendarHOC, { SHARE_SITES } from 'react-add-to-calendar-hoc';
import 'react-add-to-calendar/dist/react-add-to-calendar.css'



// STYLING
const styles = {};

const Button = props => {
  return (
    <button {...props}>{props.children}</button>
  );
}

const Modal = ({ children, isOpen, onRequestClose }) => {
  return (
    <BelongModal
      visible={isOpen}
      body={children}
    />
  );
}

const Meeting = ({ meeting, old, ...props }) => {
  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  const [showDropdown, setShowDropdown] = useState(false);

  const userAttending = meeting.userAttending === 'yes';
  const userPending = meeting.userAttending === 'pending';
  const userNotAttending = meeting.userAttending === 'no';

  const userIsStaff = meeting.participants.filter( p => p.userId === props.services.app.feathersUser._id && p.isStaff ).length > 0;


  const event = {
    title: meeting.title,
    description: '',
    location: '',
    startDatetime: moment(meeting.date).format('YYYYMMDDTHHmmssZ'),
    endDatetime: moment(meeting.date).add(1, 'hour').format('YYYYMMDDTHHmmssZ'),
    duration: 60
  };

  const AddToCalendarModal = AddToCalendarHOC(Button, Modal);

  const openMeeting = meeting => {
    var w = 630, h = 440, x = 0, y = 0; // default sizes
    if (window.screen) {
        w = window.screen.availWidth * 80 / 100;
        h = window.screen.availHeight * 80 / 100;
        x = (window.screen.availWidth - w) / 2;
        y = (window.screen.availHeight - h) / 2;
    }
    window.open(meeting.whereby.roomUrl, meeting.title, 'width='+w+',height='+h+',left='+x+',top='+y+',directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no');
  };

  const acceptMeeting = meeting => {
    feathersContext.patchAndUpdate("meetings", meeting._id, { $set: {"participants.$.attending": "yes"} }, { query: { _id: meeting._id, "participants.userId": props.services.app.feathersUser._id } });
  };

  const declineMeeting = meeting => {
    feathersContext.patchAndUpdate("meetings", meeting._id, { $set: {"participants.$.attending": "no"} }, { query: { _id: meeting._id, "participants.userId": props.services.app.feathersUser._id } });
  };

  const deleteMeeting = meeting => {
    if ( window.confirm("¿Eliminar reunión?") ) {
      feathersContext.removeAndUpdate("meetings", meeting._id);
    }
  }


  return (
    <div className={`Meeting ${old ? 'Old' : null} ${userAttending ? 'Attending' : null} ${userPending ? 'Pending' : null} ${userNotAttending ? 'NotAttending' : null}`}>
      { showDropdown &&
        <div onClick={() => setShowDropdown(false)} style={{ position: "absolute", top:0, left: 0, right: 0, bottom: 0}} />
      }
      <div className="Header">
        <h4>{meeting.title}</h4>
        {userIsStaff &&
          <button onClick={() => setShowDropdown(show => !show)}>···</button>
        }

        <ul className={`Dropdown ${showDropdown && 'Show'}`}>
          <li onClick={() => deleteMeeting(meeting)}>Eliminar</li>
        </ul>
      </div>
      <div className="Body">
        <div className="Attribute">
          <span className="Key">Fecha:</span>
          <span className="Value">
            <Moment format="LLLL">
              {meeting.date}
            </Moment>
          </span>
        </div>
        <div className="Attribute">
          <span className="Key">Participantes:</span>
          <div className="Value">
            {meeting.participants.map(p => (
              <div className="Participant">
                {p.attending === 'yes' && <span className="Icon Green icon-md-check-circle"></span>}
                {p.attending === 'no' && <span className="Icon Red icon-md-x-circle"></span>}
                {p.attending === 'pending' && <span className="Icon icon-md-question"></span>}
                <span className="ParticipantName">
                  {props.services.app.feathersUser._id === p.userId ?
                    'Tú'
                    :
                    `${p.user.firstName} ${p.user.lastName}`
                  }
                  {
                    p.isStaff && ' (Organizador)'
                  }
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {userAttending && !old &&
        <div className="Footer">
          {/*<button className="btn btn-outline btn-outline-dark">Añadir al calendario</button>*/}
          {/*<AddToCalendarModal
            className={'componentStyles'}
            linkProps={{
              className: 'linkStyles',
            }}
            event={event}
            items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL]}
          />*/}
          <AddToCalendar event={event} buttonLabel="Añadir al calendario" buttonWrapperClass="btn btn-outline btn-outline-dark btn-calendar" buttonClass="" />
          <button onClick={() => openMeeting(meeting)} className="btn btn-outline AccessButton">Acceder</button>
        </div>
      }
      {userPending && !old &&
        <div className="Footer">
          <button onClick={() => declineMeeting(meeting)}className="btn btn-outline btn-outline-dark">Rechazar</button>
          <button onClick={() => acceptMeeting(meeting)} className="btn ">Aceptar</button>
        </div>
      }
    </div>
  );
}

export default connect(
  (state) => ({
    services: {
      app: state.services.app,
    },
  }),
  null
)(Meeting);