import React, { useEffect, useState } from 'react';

import { useIntl, FormattedMessage } from "react-intl";

export default props => {

  const [secondsLeft, setSecondsLeft] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (secondsLeft > 0) setSecondsLeft(secondsLeft-1);
    }, 1000);
  }, [secondsLeft]);

  useEffect(() => {

    if (props.date) {
      setSecondsLeft(
        parseInt(
          new Date(props.date).getTime() / 1000 -
            new Date().getTime() / 1000
        )
      );
    }
  }, [props.date])

  // get total seconds between the times
  var delta = secondsLeft;
  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;
  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;
  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;
  // what's left is seconds
  const seconds = delta % 60;  // in theory the modulus is not required

  const pad = val => {
    if (val < 9) {
      return '0' + val;
    } else {
      return val;
    }
  }

  return (
    <div className="Counter">
      <div className="Element">
        <div className="Value">{pad(days)}</div>
        <div className="Label"><FormattedMessage id="hall.days" /></div>
      </div>
      <div className="Element">
        <div className="Value">:</div>
      </div>
      <div className="Element">
        <div className="Value">{pad(hours)}</div>
        <div className="Label"><FormattedMessage id="hall.hours" /></div>
      </div>
      <div className="Element">
        <div className="Value">:</div>
      </div>
      <div className="Element">
        <div className="Value">{pad(minutes)}</div>
        <div className="Label"><FormattedMessage id="hall.min" /></div>
      </div>
      <div className="Element">
        <div className="Value">:</div>
      </div>
      <div className="Element">
        <div className="Value">{pad(seconds)}</div>
        <div className="Label"><FormattedMessage id="hall.sec" /></div>
      </div>
    </div>
  );
}