import { store } from "../../store";

import moment from 'moment';

export const getGrouped = () => {
  const { items } = store.getState().data.meetings;

  const currentDate = moment().subtract(1, 'hour');
  console.log("THE ITEMSSSS", items);
  return {
    pending: items.filter( i => {
      return moment(i.date) >= currentDate && i.userAttending === 'pending' && i.participants.filter(p => p.attending == 'no').length === 0
    } ),
    upcoming: items.filter( i => moment(i.date) >= currentDate ),
    past: items.filter( i => moment(i.date) < currentDate ),
  };
};
