import React, { useState, useContext, Fragment } from 'react';

// OWN ROOT COMPONENTS
import TopBar from '../../components/TopBar';
import LeftBar from '../../components/LeftBar';
import Curtain from '../../components/Curtain';
import Profile from '../../components/Profile';

import UserList from './components/UserList';
import PlaceholderAvatar from '../../components/PlaceholderAvatar';
import ScaledImage from '../../components/ScaledImage';
import Conversations from './components/Conversations';
import Conversation from './components/Conversation';
import Meetings from './components/Meetings';
import MeetingForm from './components/MeetingForm';
import MeetingsGrid from './components/MeetingsGrid';
import MeetingModal from './components/MeetingModal';


// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { getGrouped as getMeetingsGrouped } from '../../data/meetings/selectors';
import { bindActionCreators } from "redux";
import * as messagesActionCreators from "../../data/messages/actions";
import { Link } from "react-router-dom";

// OTHER UTILS
import { useIntl, FormattedMessage } from "react-intl";
import moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";


registerLocale('es', es)

// STYLING
const styles = {};

const Chat = props => {
  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  const [selectedUser, setSelectedUser] = useState(null);

  const [activeModule, setActiveModule] = useState("users");
  const [meetingsFilter, setMeetingsFilter] = useState("active");

  const [userChatting, setUserChatting] = useState(false);
  const [userMeeting, setUserMeeting] = useState(false);

  // MODAL STATES
  const [meeting, setMeeting] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [users, setUsers] = useState([]);


  const intl = useIntl();

  const lang = intl.locale;

  const searchUser = query => {
    if (query !== "") {
      feathersContext.find("users", { query: {
                    $or: [
                      { firstName: { $like: query, $search: query } },
                      { lastName: { $like: query, $search: query } },
                      { subtitle: { $like: query, $search: query } },
                    ],
                  } }).then( r => {
                    setUsers(r.data);
                  })
    } else {
      setUsers([]);
    }

  }



  const newMeeting = () => {
    setModalVisible(true);
  };

  const editMeeting = meeting => {
    setMeeting(meeting);
    setModalVisible(true);
  };

  const sidebarHeader = () => {
    return (
      <div className="Header Selector">
        <h3 className={`WithClick ${activeModule === 'users' && 'Active'}`} onClick={() => {
          setSelectedUser(null);
          setUserChatting(false);
          setUserMeeting(false);
          setActiveModule("users");
        }}><FormattedMessage id="networking.users" /></h3>
        {props.data.conversations.items.length > 0 &&
          <h3 className={`WithClick ${activeModule === 'conversations' && 'Active'}`} onClick={() => {
            setSelectedUser(null);
            setUserChatting(false);
            setUserMeeting(false);
            setActiveModule("conversations");
          }}>
            {props.data.unreadConversations > 0 &&
              <div className="Badge">{props.data.unreadConversations}</div>
            }
            <FormattedMessage id="networking.chats" />
          </h3>
        }
        <h3 className={`WithClick ${activeModule === 'meetings' && 'Active'}`} onClick={() => {
          setSelectedUser(null);
          setUserChatting(false);
          setUserMeeting(false);
          setActiveModule("meetings");
        }}>
          {props.data.pendingMeetings.length > 0 &&
            <div className="Badge">{props.data.pendingMeetings.length}</div>
          }
          <FormattedMessage id="networking.meetings" />
        </h3>
      </div>
    );
  }

  return (
    <Fragment>
      <TopBar />
      <div className="outmostContainer">
        <LeftBar slug={props.services.app.slug} />
        <div className="outer-container FixedHeight">
          <Curtain
            manifest={props.services.app.manifest}
            appointments={props.data.appointments}
            rooms={props.data.rooms}
            slug={props.services.app.slug}
            feathersAuthenticated={props.services.app.feathersAuthenticated}
          />

          {activeModule === "users" &&
            <UserList
              userClicked={(user) => {
                // props.actions.messages.empty();
                // props.actions.messages.loading();
                // props.actions.messages.setCurrentConversation(user._id);
                setSelectedUser(user);
                setUserChatting(false);
                setUserMeeting(false);
                // feathersContext.readConversation(user._id);
              }}
              omitHeader
              customHeader={sidebarHeader}
              className="MeetingsUsers sidebar folded"
            />
          }

          {activeModule === "conversations" &&
            <Conversations
              activeConversation={selectedUser}
              omitHeader
              customHeader={sidebarHeader}
              className="MeetingsUsers sidebar folded"
              conversationClicked={(c) => {
                if (c.counterpartUser !== selectedUser) {
                  props.actions.messages.empty();
                  props.actions.messages.loading();
                  props.actions.messages.setCurrentConversation(c.counterpartUser._id);
                  setSelectedUser(c.counterpartUser);
                  setUserChatting(true);
                  feathersContext.readConversation(c.counterpartUser._id);
                }

              }}

            />
          }

          {activeModule === "meetings" &&
            <Meetings
              omitHeader
              customHeader={sidebarHeader}
              meetingsFilter={meetingsFilter}
              setMeetingsFilter={setMeetingsFilter}
              className="MeetingsUsers sidebar folded"
            />
          }


          <div className="central folded">
            <div className="CentralModule NetworkingModule">
              <div className={`Module Side UserDetail ${activeModule == 'users' && selectedUser ? '' : 'Folded'}`}>
                {selectedUser && activeModule == 'users' &&
                  <Fragment>
                    <div className="UserInfo">
                      {selectedUser.picture ? (
                        <ScaledImage image={selectedUser.picture} className="Avatar" />
                      ) : (
                        <PlaceholderAvatar
                          user={selectedUser}
                          themeColor={props.services.app.manifest.themeColor}
                          className="Avatar"
                        />
                      )}
                      <h2 className="Name">{selectedUser.firstName} {selectedUser.lastName}</h2>
                      <h3 className="Subtitle">{selectedUser.subtitle}</h3>
                    </div>
                    <button onClick={() => {
                      setUserMeeting(false)
                      setUserChatting(true)
                    }} className={`btn btn-block ${!userChatting && 'btn-outline'}`}><FormattedMessage id="networking.sendMessage" /></button>
                    <button onClick={() => {
                      setUserChatting(false)
                      setUserMeeting(true)
                    }} className={`btn btn-block ${!userMeeting && 'btn-outline'}`}><FormattedMessage id="networking.setMeeting" /></button>
                    <p>{selectedUser.bio}</p>
                  </Fragment>
                }
              </div>
              <div className="Module Main">
                {activeModule === "users" &&
                  (
                    selectedUser ?
                      userChatting ?
                        <Conversation sender={selectedUser} />
                        :
                        userMeeting ?
                          <MeetingForm
                            user={selectedUser}
                            onSuccess={() => {
                              setSelectedUser(null);
                              setUserChatting(false);
                              setUserMeeting(false);
                              setActiveModule("meetings");
                            }}
                          />
                          :
                          <div className="Placeholder">
                            <span className="icon-md-user-circle"></span>
                            <span><FormattedMessage id="networking.userPlaceholder" /></span>
                          </div>
                    :
                    <div className="Placeholder">
                      <span className="icon-md-user-circle"></span>
                      <span><FormattedMessage id="networking.selectUser" /></span>
                    </div>
                  )
                }

                {activeModule === "conversations" &&
                  (
                    selectedUser && userChatting ?
                      <Conversation sender={selectedUser} />
                    :
                    <div className="Placeholder">
                      <span className="icon-md-user-circle"></span>
                      <span><FormattedMessage id="networking.selectConv" /></span>
                    </div>
                  )
                }

                {activeModule === "meetings" &&
                  <MeetingsGrid
                    meetingsFilter={meetingsFilter}
                    newMeeting={newMeeting}
                    editMeeting={editMeeting}
                    goToUsers={() => setActiveModule("users")}
                  />
                }

              </div>
              <div className="Module Side Folded"></div>
            </div>
          </div>
          <Profile />
        </div>
      </div>
      <MeetingModal
        userId={props.services.app.feathersUser._id}
        meeting={meeting}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        lang={lang}
      />
    </Fragment>
  );
}

export default connect(
  (state) => ({
    data: {
      appointments: state.data.appointments.items,
      rooms: state.data.rooms.items,
      pendingMeetings: getMeetingsGrouped().pending,
      conversations: state.data.conversations,
      unreadConversations: state.data.conversations.unread
    },
    services: {
      app: state.services.app,
    },

  }),
  (dispatch) => ({
    actions: {
      messages: bindActionCreators(messagesActionCreators, dispatch),
    },
  })
)(Chat);