import * as actionTypes from "./actionTypes";

const update = (moduleId, items) => ({
  type: actionTypes.UPDATE,
  moduleId,
  items,
});

export const append = (moduleId, item) => ({
  type: actionTypes.APPEND,
  moduleId,
  item
});

export const updateOne = (moduleId, item) => ({
  type: actionTypes.UPDATE_ONE,
  moduleId,
  item,
});

export const destroy = (moduleId, item) => ({
  type: actionTypes.DESTROY,
  moduleId,
  item,
});

const loading = moduleId => ({
  type: actionTypes.LOADING,
  moduleId
});


