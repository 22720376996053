import React, { createContext, useContext, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

export const MixPanelContext = createContext();
export const useMixPanel = () => useContext(MixPanelContext);

export const MixPanelProvider = ({ slug, children }) => {

  useEffect(() => {
    mixpanel.init("1c2ad0dca35bbcdf21f0740aabccf4e0", { "api_host": "https://api-eu.mixpanel.com" }, "");
  }, []);

  const setMixpanelUser = (user) => {
    if (process.env.NODE_ENV === 'production') {
      mixpanel.identify(user._id);
      mixpanel.people.set({
        "$email": user.email,    // only reserved properties need the $
        "USER_ID": user._id,    // use human-readable names
        "firstName": user.firstName,
        "lastName": user.lastName,
        "subtitle": user.subtitle,
        "audiences": user.audiences,
      });
    }
  };

  const trackMixpanelEvent = (event, data = {}) => {
    if (process.env.NODE_ENV === 'production') {
      mixpanel.track(event, { appId: slug, appType: 'virtualVenues', ...data });
    }
  };

  return (<MixPanelContext.Provider value={{ setMixpanelUser, trackMixpanelEvent }}>{children}</MixPanelContext.Provider>);
};

const Mixpanel = mixpanel;

export default Mixpanel;