import React, { useState, useEffect } from "react";


export const scaledImageUrl = (key, width = 100, height = 100, fit = 'inside', format = 'jpg') => {
  const request = {
    bucket: "ubelong-dev2", // S3 bucket name
    key: key, // path to your file on the S3 bucket (ex. photos/face1.png)
    edits: {
      // smartCrop: faceCrop ? {
      //   faceIndex: 0,   // zero-based index of detected faces
      //   padding: 130    // padding expressed in pixels, applied to all sides
      // } : false,
      // normalize: true,
      // small tip, if you have a mobile app you can use the following 2 lines of code to optimise your images further
      webp: true, // if android, android loves webp
      jpeg: true, // if iOS, iOS doesnt display webp so lets convert it
      toFormat: format,
      resize: {
        width: width,
        height: height,
        fit: fit
      },
    },
  };
  const strRequest = JSON.stringify(request);
  const encRequest = btoa(strRequest);

  return `https://d14kpv59isoic6.cloudfront.net/${encRequest}`;
}

export default (props) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (props.image) {
      setImageUrl(props.image);
    }
  }, [props.image, props.width, props.height, props.fit, props.format]);

  return imageUrl ? (
    <img
      src={imageUrl}
      {...props}
    />
  ) : (
    <span>No image</span>
  );
};
