import React, { useContext, useCallback, useEffect, useState } from "react";

// OWN ROOT COMPONENTS

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { getGrouped } from "../../../../data/users/selectors";
import ScaledImage from "../../../../components/ScaledImage";
import PlaceholderAvatar from "../../../../components/PlaceholderAvatar";

// OTHER UTILS
import { useIntl, FormattedMessage } from "react-intl";
import Select from 'react-select'




const UsersColumn = ({ ...props }) => {

  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  const intl = useIntl();

  const [query, setQuery] = useState("");
  const [audiences, setAudiences] = useState([]);
  const [selectedAudiences, setSelectedAudiences] = useState([]);

  useEffect(() => {
    feathersContext
      .find("audiences", {
        query: {
          isNetworking: true,
          $limit: -1,
          $sort: {
            name: 1,
          },
        },
      }).then(r => {
        setAudiences(r.map(a => ({ value: a._id, label: a.name })))
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }, [feathersContext]);

  useEffect(() => {
    const tq = {
      invisible: false,
      $sort: {
        lastName: 1,
      },
    };
    if (selectedAudiences.length > 0) tq.audiences = { $in: selectedAudiences };
    feathersContext
      .findAndUpdate("users", {
        query: tq,
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }, [feathersContext, selectedAudiences]);

  const loadMore = useCallback(
    (info) => {
      const items = props.data.users.items;
      if (items.length < props.data.users.total) {
        const tq = {
          ...(query != ""
            ? {
                invisible: false,
                $or: [
                  { firstName: { $like: query, $search: query } },
                  { lastName: { $like: query, $search: query } },
                  { subtitle: { $like: query, $search: query } },
                ],
              }
            : { invisible: false }),
          $skip: items.length,
          $sort: {
            lastName: 1,
          },
        };
        if (selectedAudiences.length > 0) tq.audiences = { $in: selectedAudiences };
        feathersContext
          .findAndAppend("users", {
            query: tq,
          })
          .catch((e) => {
            console.log("Error", e);
          });
      }
    },
    [feathersContext, selectedAudiences, props.data.users.items, props.data.users.total, query]
  );

  const handleSearch = useCallback((t) => {
    setQuery(t);
    const tq = {
      ...(t != ""
        ? {
            invisible: false,
            $or: [
              { firstName: { $like: t, $search: t } },
              { lastName: { $like: t, $search: t } },
              { subtitle: { $like: t, $search: t } },
            ],
          }
        : { invisible: false }),
      $sort: {
        lastName: 1,
      },
    };
    if (selectedAudiences.length > 0) tq.audiences = { $in: selectedAudiences };
    feathersContext
      .findAndUpdate("users", {
        query: tq,
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }, [feathersContext, selectedAudiences]);

  return (
    <div className={`Module Users ${props.className}`}>
      {!props.omitHeader &&
        <div className="Header">
          <span class="icon-md-template"></span>
          <h3><FormattedMessage id="networking.users.title" /></h3>
        </div>
      }
      {props.customHeader ? props.customHeader() : null}
      <div className="List">
        <div className="Search">
          <input className="SearchInput" placeholder={intl.formatMessage({ id: "networking.users.search" })} onChange={(e) => handleSearch( e.target.value )} val={query} />
          <Select
            onChange={(elem) => {
              setSelectedAudiences(elem.map(a => a.value))
            }}
            isMulti
            className="Select"
            options={audiences}
            placeholder={intl.formatMessage({ id: "networking.users.audiences" })}
          />
        </div>
        {props.data.users.groups.map((g) => (
          <div className="Initial">
            <h4>{g.title}</h4>
            {g.data.map((user) => (
              <div onClick={() => props.userClicked(user)} className="User">
                {user.picture ? (
                  <ScaledImage image={user.picture} className="Avatar" />
                ) : (
                  <PlaceholderAvatar
                    user={user}
                    themeColor={props.services.app.manifest.themeColor}
                    className="Avatar"
                  />
                )}
                <div className="UserText">
                  <h5>
                    {user.firstName} {user.lastName}
                  </h5>
                  <span>{user.subtitle}</span>
                </div>
              </div>
            ))}
          </div>
        ))}
        {props.data.users.items.length < props.data.users.total &&
          <button onClick={loadMore} className="btn btn-outline LoadMore"><FormattedMessage id="networking.users.loadMore" /></button>
        }
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    data: {
      users: {
        ...state.data.users,
        groups: getGrouped(),
      },
    },
    services: {
      app: state.services.app
    }
  }),
  null
)(UsersColumn);