import React, { Fragment } from 'react';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../services/app/actions';

import ReactMarkdown from "react-markdown";
const htmlParser = require('react-markdown/plugins/html-parser')

const Alert = props => {

  const dismiss = () => {
    props.actions.app.hideAlert();
  }


  const parse = htmlParser({
    isValidNode: (node) => node.type !== 'script',
    processingInstructions: [/* ... */]
  })

  return (
    <Fragment>
      <div className={`AlertOverlay ${props.alert.visible ? '' : 'hidden'}`} onClick={dismiss}></div>
      {props.alert && props.alert.visible ?
        <div className={props.alert.modal ? 'Modal' : 'Alert'}>
          {props.alert.html ?
            <div style={{ marginBottom: 15 }}>
              {props.alert.title ? <h2 className="Title">{props.alert.title}</h2> : null}
              <ReactMarkdown children={props.alert.html} htmlParser={parse} skipHtml={false} allowDangerousHtml />
            </div>
            :
            <Fragment>
              <h2 className="Title">{props.alert.title}</h2>
              {props.alert.bodyElement ?
                <div className="BodyElement">{props.alert.bodyElement}</div>
              :
                <p className="Body">{props.alert.body}</p>
              }

            </Fragment>
          }

          <div className={`Actions ${!props.alert.cancelText && 'one'}`}>
            {props.alert.cancelText &&
              <button className="btn btn-outline" type="button" onClick={dismiss}>{props.alert.cancelText}</button>
        }

            <button className="btn" type="button" onClick={dismiss}>{props.alert.okText}</button>
        </div>
      </div>
      : null}
    </Fragment>
  );
}

export default connect(
  (state, ownProps) => ({
    alert: state.services.app.alert
  }),
  (dispatch) => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch),
    },
  })
)(Alert);