import React, { useEffect } from 'react';

import { Widget } from '@typeform/embed-react'

import { connect } from "react-redux";

import { useIntl, FormattedMessage } from "react-intl";

const ExternalWidget = props => {

  const { feathersAuthenticated, user, module } = props;

  const userName = feathersAuthenticated ? `${user.firstName} ${user.lastName}` : '';

  const intl = useIntl();
  const lang = intl.locale;


  switch (module.type) {
    case 'web':
      const url = props.module.code[lang];
      return (
        <div class="marginated">
          <div class="title">
            <span className={`icon-md-${props.module.icon}`}></span>
            <h3>
              {props.module.title[lang]}
            </h3>
          </div>
          <div style={{ position: 'relative', overflow: 'hidden' }}>
            <iframe
              src={url} height="100%" width="100%" frameborder="0" style={{ minHeight: props.module.height }}></iframe>
          </div>
        </div>
      );
    case 'slido':
      const slidoUrl = feathersAuthenticated ? `https://app.sli.do/event/${props.module.code[lang]}/live/questions?lang=es_ES&user_name=${userName}&user_email=${user.email}&user_company=${user.email}` : `https://app.sli.do/event/${props.module.code}/live/questions?lang=es_ES}`
      return (
        <div class="marginated">
          <div class="title">
            <span className={`icon-md-${props.module.icon}`}></span>
            <h3>
              {props.module.title[lang]}
            </h3>
          </div>
          <div style={{ position: 'relative', overflow: 'hidden' }}>
            <iframe
              src={slidoUrl} height="100%" width="100%" frameborder="0" style={{ minHeight: props.module.height, position: 'relative', top: -56, marginBottom: -56 }}></iframe>
          </div>
        </div>
      );
    break;
    case 'typeform':
      const hiddenFields = feathersAuthenticated ? {
        nombre: userName,
        mail: user.email
      } : {};
      return (
        <div class="marginated">
          <div class="title">
            <span className={`icon-md-${props.module.icon}`}></span>
            <h3>
              {props.module.title[lang]}
            </h3>
          </div>

          <Widget
            id={props.module.code[lang]}
            hidden={hiddenFields}
            style={{ width: '100%', height: props.module.height}}
            className="typeform-widget" />
        </div>
      );
    break;
    default:
      return <div>Unsupported</div>;
  }



}

export default connect(
  (state, ownProps) => ({
    manifest: state.services.app.manifest,
  }),
  null
)(ExternalWidget);