import React from 'react';

import moment from "moment";

import { useNavigate } from "react-router-dom";

import { useIntl, FormattedMessage } from "react-intl";

export default props => {

  const navigate = useNavigate();

  const { appointment, meeting, rooms, lang, userId, acceptMeeting, declineMeeting, deleteMeeting, editMeeting } = props;

  const openMeeting = meeting => {
    var w = 630, h = 440, x = 0, y = 0; // default sizes
    if (window.screen) {
        w = window.screen.availWidth * 80 / 100;
        h = window.screen.availHeight * 80 / 100;
        x = (window.screen.availWidth - w) / 2;
        y = (window.screen.availHeight - h) / 2;
    }
    window.open(meeting.whereby.roomUrl, meeting.title, 'width='+w+',height='+h+',left='+x+',top='+y+',directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no');
  };

  const matchingRooms = rooms.filter(r => appointment.roomId && r._id.toString() == appointment.roomId.toString());
  const roomName = matchingRooms.length > 0 ? matchingRooms[0].title[lang] : '';

  const gotoRoom = () => {
    navigate(`../${matchingRooms[0].slug}`);
  }

  const handleEdit = () => {};

  if (appointment.startDate) {

    return (
      <div className={`Meeting Appointment ${roomName ? 'Navigable' : ''}`} onClick={roomName ? gotoRoom : null}>
        <div className="MetaRow">
          <span className="Key"><FormattedMessage id="networking.appointment.title" /></span>
          <span className="Value Bold">{appointment.title[lang]}</span>
        </div>
        {roomName ?
          <div className="MetaRow">
            <span className="Key"><FormattedMessage id="networking.appointment.room" /></span>
            <span className="Value">{roomName}</span>
          </div>
          : null
        }
         <div className="MetaRow">
          <span className="Key"><FormattedMessage id="networking.appointment.time" /></span>
          <span className="Value">{moment(appointment.startDate).locale(lang).format("dddd, LT")} -{" "}
                              {moment(appointment.endDate)
                                .locale(lang)
                                .format("LT")}</span>
        </div>
      </div>
    );
  } else {

    let status = '';

    // Confirmed
    if (appointment.participants.length == appointment.participants.filter(p => p.attending == 'yes').length) status = 'Confirmed';
    // Cancelled
    else if (appointment.participants.filter(p => p.attending == 'no').length > 0) status = 'Cancelled';
    else {
      if (appointment.userAttending == 'yes') status = 'Waiting';
      else if (appointment.userAttending == 'pending') status = 'Pending';
    }

    return (
      <div className={`Meeting ${status}`} onClick={() => status == 'Confirmed' ? openMeeting(appointment) : {}}>
        <div className="MetaRow">
          <span className="Key"><FormattedMessage id="networking.meeting.title" /></span>
          <span className="Value Bold">{appointment.title}</span>
        </div>
        <div className="MetaRow">
          <span className="Key"><FormattedMessage id="networking.meeting.attendees" /></span>
          <span className="Value">
            {appointment.participants.map(p => (
              <React.Fragment>
                {p.attending === 'yes' && <span className="Icon Green icon-md-check-circle"></span>}
                {p.attending === 'no' && <span className="Icon Red icon-md-x-circle"></span>}
                {p.attending === 'pending' && <span className="Icon icon-md-question-mark-circle"></span>}
                {
                  p.user._id == userId ? (
                    <span className="Participant You">
                      <FormattedMessage id="networking.meeting.you" />
                    </span>
                  ) : (
                    <span className="Participant">
                      {`${p.user.firstName} ${p.user.lastName}`}
                    </span>
                  )
                }
              </React.Fragment>
            ))}
          </span>
        </div>
        <div className="MetaRow">
          <span className="Key"><FormattedMessage id="networking.meeting.time" /></span>
          <span className="Value">{moment(appointment.date).locale(lang).format("dddd, LT")} -{" "}
                              {moment(appointment.date).add(15, 'minutes')
                                .locale(lang)
                                .format("LT")}</span>
        </div>
        {appointment.message ?
          <div className="MetaRow">
            <span className="Key"><FormattedMessage id="networking.meeting.message" /></span>
            <span className="Value Message">{appointment.message}</span>
          </div>
          : null }
        {['Waiting'].includes(status) ?
          <div className="Actions">
            <span class="icon-md-trash" onClick={() => deleteMeeting(appointment)}></span>
            <span class="icon-md-pencil-alt" onClick={() => editMeeting(appointment)}></span>
          </div>
        : null}
        {['Confirmed', 'Waiting', 'Pending'].includes(status) ?
          <div className="Status">
            {status == 'Confirmed' ?
              <React.Fragment>
                                <span><FormattedMessage id="networking.meeting.accepted" /></span>
                                <span class="icon-md-check"></span>
              </React.Fragment>
            :
              status == 'Waiting' ?
                <React.Fragment>
                  <span><FormattedMessage id="networking.meeting.requested" /></span>
                  <span class="icon-md-download"></span>
                </React.Fragment>
                :
                <React.Fragment>
                  <button className="btn Refuse" onClick={() => declineMeeting(appointment)}><FormattedMessage id="networking.meeting.reject" /></button>
                  <button className="btn Accept" onClick={() => acceptMeeting(appointment)}><FormattedMessage id="networking.meeting.accept" /></button>
                </React.Fragment>
            }
          </div>
        : null}

      </div>
    );
  }


}