import React, { useState, useContext, Fragment, useEffect } from 'react';

// OWN ROOT COMPONENTS
import TopBar from '../../../../components/TopBar';


// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";
import { useIntl, FormattedMessage } from "react-intl";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as messagesActionCreators from "../../../../data/messages/actions";
import { Link } from "react-router-dom";

// OTHER UTILS
import moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'

registerLocale('es', es)

// STYLING
const styles = {};

const Meetings = props => {
  const intl = useIntl();
  const lang = intl.locale;

  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  const [days, setDays] = useState([]);
  const [slots, setSlots] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);

  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);
  const [slot, setSlot] = useState(null);

  const { user } = props;


  useEffect(() => {
    feathersContext.find("slots", {
      query: {
        userId: user._id
      }
    }).then(r => {
      setSlots(r);
    }).catch(e => {
      alert(JSON.stringify(e))
    })
  }, [feathersContext, user._id]);

  useEffect(() => {
    const ld = [];
    slots.map(s => {
      if (!ld.some(d => d.value.format('YYYYMMDD') == moment(s.date).format('YYYYMMDD'))) {
        ld.push({
          value: moment(s.date),
          label: moment(s.date).locale(lang).format('dddd DD')
        })
      }
    })
    setDays(ld);
  }, [lang, slots]);

  const valid = () => {
    return title !== null && title !== '' && slot !== null;
  }

  const submitMeeting = () => {
    if ( valid() ) {
      const newMeeting = {
        title,
        message,
        date: slot.format(),
        participants: [
          { userId: props.services.app.feathersUser._id, isStaff: true, attending: 'yes' },
          { userId: user._id, isStaff: false, attending: 'pending' }
        ],
      }
      feathersContext.create("meetings", newMeeting).then( r => {
        props.onSuccess();
      }).catch( e => {
        alert(JSON.stringify(e))
      })
    }

  }


  return (
    <Fragment>
      <div className="Header">
        <div className="Left">
          <span class="icon-md-template"></span>
          <h3><FormattedMessage id="networking.form.meetingWith" /> {user.firstName} {user.lastName}</h3>
        </div>
      </div>
      <div className="MeetingForm">
        <div className="Field">
          <label><FormattedMessage id="networking.form.meetingTitle" /></label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="Field">
          <label><FormattedMessage id="networking.form.message" /></label>
          <textarea rows={4} value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
        </div>
        <div className="Field">
          <label><FormattedMessage id="networking.form.date" /></label>
          <Select
            onChange={(elem) => {
              setSelectedDay(elem.value)
            }}
            className="Select"
            options={days}
            placeholder={intl.formatMessage({ id: "networking.form.selectDay" })}
          />
        </div>
        {selectedDay !== null &&
          <div className="Field Hours">
            <label><FormattedMessage id="networking.form.slots" /></label>
            <ul>
              {slots.filter(s => selectedDay.isSame(s.date, 'day')).map(s => (
                <li
                  onClick={() => {
                    if(s.available) setSlot(moment(s.date))
                  }}
                  className={`${slot && slot.isSame(s.date, 'minute') ? 'Selected' : ''} ${s.available ? '' : 'Unavailable'}`}
                >
                  {moment(s.date).format("HH:mm")} - {moment(s.date).add(s.interval, 'minutes').format("HH:mm")}
                </li>
              ))}
            </ul>
          </div>
        }

      </div>
      <div className="Footer">
        <button onClick={() => {}} className="btn btn-outline"><FormattedMessage id="networking.form.cancel" /></button>
        <button onClick={submitMeeting} className="btn" disabled={!valid()}><FormattedMessage id="networking.form.requestMeeting" /></button>
      </div>
    </Fragment>
  );
}

export default connect(
  (state) => ({
    services: {
      app: state.services.app,
    },
  }),
  null
)(Meetings);