import { createStore, applyMiddleware } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";

import dataReducer from "./data/reducer";
import servicesReducer from "./services/reducer";

const slug =
  window.location.pathname.split("/").filter((p) => p !== "")[0] || "unknown";

const config = {
  key: slug,
  storage,
  blacklist: ["data"],
};

const appReducer = persistCombineReducers(config, {
  services: servicesReducer,
  data: dataReducer,
});

const enhancer = applyMiddleware(thunk);

const store = createStore(appReducer, enhancer);

const persistor = persistStore(store);

export { store, persistor };

export default store;
