import React, { Fragment, useEffect, useState, useContext, useCallback } from "react";

// OWN ROOT COMPONENTS
import TopBar from "../../components/TopBar";
import LeftBar from "../../components/LeftBar";
import Curtain from "../../components/Curtain";
import Profile from '../../components/Profile';
import WherebyView from "../../components/WherebyView";
import LlucanaView from "../../components/LlucanaView";
import QuestionsWidget from "../../components/QuestionsWidget";
import SurveyBlocksWidget from "../../components/SurveyBlocksWidget";
import ExternalWidget from "../../components/ExternalWidget";
import ScaledImage, { scaledImageUrl } from "../../components/ScaledImage";
// import QuestionsWidget from "../../components/QuestionsWidget";
// import SurveyBlocksWidget from "../../components/SurveyBlocksWidget";

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";
import { useMixPanel } from '../../services/mixpanel/MixpanelProvider';

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../../services/app/actions";

// OTHER UTILS
import { useParams, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useIntl, FormattedMessage } from "react-intl";

// STYLING
const styles = {};

var HtmlToReactParser = require("html-to-react").Parser;

const Room = ({ manifest, slug, feathersAuthenticated, user, ...props }) => {
  const intl = useIntl();
  const lang = intl.locale;

  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);
  const { trackMixpanelEvent } = useMixPanel();

  const [fullModules, setFullModules] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);


  let { roomId } = useParams();

  const { pathname } = useLocation();


  const room = props.data.rooms.filter((r) => r.slug === roomId)[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    trackMixpanelEvent("visitRoom", { roomId });
  }, [roomId, trackMixpanelEvent]);

  const loadModules = useCallback(() => {

    const room = props.data.rooms.filter((r) => r.slug === roomId)[0];
    //alert(JSON.stringify(room));
    if(room && room.modules) {
      feathersContext.find("modules", {
        query: {
          _id: {
            $in: room.modules
          },
          active: true,
          $sort: { position: 1 }
        },
        paginate: false,
        $limit: 100
      }).then(r => {
        setFullModules(r);
      })
    }
  }, [feathersContext, props.data.rooms, roomId]);




  useEffect(() => {
    loadModules();
  }, [feathersContext, loadModules, room]);

  const showAlert = (alert) => {
    const { title, body, roomId, visible } = alert;

    if (visible) {
      props.actions.app.showAlert({
        modal: true,
        title: title[lang],
        html: body[lang],
        okText: intl.formatMessage({ id: "alerts.close" })
      });
    }

  };



  useEffect(() => {
    feathersContext.client.service('modules').on("created", loadModules);
    feathersContext.client.service('modules').on("updated", loadModules);
    feathersContext.client.service('modules').on("patched", loadModules);
    feathersContext.client.service('modules').on("removed", loadModules);

    feathersContext.client.service('alerts').on("patched", showAlert);


    return () => {
      feathersContext.client.service('modules').off("created", loadModules);
      feathersContext.client.service('modules').off("updated", loadModules);
      feathersContext.client.service('modules').off("patched", loadModules);
      feathersContext.client.service('modules').off("removed", loadModules);

      feathersContext.client.service('alerts').off("patched", showAlert);

    };
  }, [feathersContext.client, loadModules, props.data.rooms, showAlert]);


  if (room && room.description) {
    var htmlToReactParser = new HtmlToReactParser();
    var descriptionElement = htmlToReactParser.parse(room.description[lang]);
  }

  const renderEmbed = () => {
    if (room.embedType === "external") {
      return (
        <LlucanaView
          embedRef={room.embedRef[lang]}
          room={room}
        />
      );
    }
    if (room.embedType === "whereby") {
      return <WherebyView slug={slug} room={room} fullScreen={fullScreen} toggleFullScreen={() => setFullScreen(fs => !fs)} />;
    }
  };


  return room ? (
    <Fragment>
      {fullScreen || <TopBar /> }
      <div className="outmostContainer" style={ fullScreen ? { position: 'fixed', width: '100%', height: '100%' } : {} }>
        {fullScreen || <LeftBar slug={slug} /> }
        {isMobile && 1 === 2 ? (
          <div className="outer-container">
            <iframe
              title="Jitsi mobile"
              frameborder="0"
              src={`https://jitsi.padcelona.com/${slug}`}
              style={{ width: "100%" }}
            ></iframe>
          </div>
        ) : (
          <div className="outer-container" style={ fullScreen ? { height: '100%', margin: 0 } : {} }>
            <div className="central folded">
              <div className="CentralModule Module" style={ fullScreen ? { margin: 0 } : {} }>
                <div className="embed-outer" >{renderEmbed()}</div>
                {fullScreen ||
                  <div className="info">
                    <div className="Header">
                      {room.logo_medium && (
                        <img
                          className="RoomLogo"
                          src={room.logo_medium}
                          alt={room.name}
                        />
                      )}
                      {!room.titleHidden && room.title && room.title[lang] && room.title[lang] != "" ?
                        <h2>{room.title[lang]}</h2>
                        :
                        null
                      }

                    </div>
                    {descriptionElement}
                  </div>
                }
              </div>
            </div>
            {fullScreen ||
              <div className="sidebar folded">
                <div
                  className="logo"
                  style={{
                    backgroundColor: manifest.themeColor,
                    backgroundImage: `url(${manifest.logo})`,
                  }}
                ></div>
                <div className="sidebar-content">
                  {fullModules &&
                    fullModules.map((am) => {
                      switch (am._type) {
                        case "questionsModules":
                          return (
                            <QuestionsWidget
                              showsFeed={am.showsFeed}
                              module={am}
                              moduleId={am._id}
                              themeColor={manifest.themeColor}
                            />
                          );
                        case "votingModules":
                          return (
                            <SurveyBlocksWidget
                              module={am}
                              moduleId={am.id}
                              themeColor={manifest.themeColor}
                            />
                          );
                        case "externalModules":
                          return (
                            <ExternalWidget
                              module={am}
                              moduleId={am.id}
                              themeColor={manifest.themeColor}
                              feathersAuthenticated={props.services.app.feathersAuthenticated}
                              user={props.services.app.feathersUser}
                              locale={lang}
                            />
                          );
                        default:
                          return null;
                      }
                    })}
                </div>
              </div>
            }
            <Curtain
              manifest={manifest}
              appointments={props.data.appointments}
              rooms={props.data.rooms}
              slug={props.services.app.slug}
              feathersAuthenticated={props.services.app.feathersAuthenticated}
              user={props.services.app.feathersUser}
            />
            <Profile />
          </div>
        )}
      </div>
    </Fragment>
  ) : null;
};

export default connect(
  (state) => ({
    data: {
      appointments: state.data.appointments.items,
      rooms: state.data.rooms.items,
    },
    manifest: state.services.app.manifest,
    services: {
      app: state.services.app,
    },
  }),
  (dispatch) => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch),
    },
  })
)(Room);
