import React, { useContext, useEffect, useCallback, useState } from 'react';

// OWN ROOT COMPONENTS

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as questionsActionCreators from '../../data/questions/actions';

// OTHER UTILS
import { useParams } from "react-router-dom";
import Moment from 'react-moment';

// STYLING
const styles = {};

const Component = props => {
  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);
  const [module, setModule] = useState({});
  const [moreComing, setMoreComing] = useState(true);

  let { moduleId } = useParams();

  const items = props.data.questions.modules[moduleId] ? props.data.questions.modules[moduleId].items : [];

  const loadMore = useCallback(() => {
    if (items.length > 0) {
      // INITIAL LOAD OF QUESTIONS
      feathersContext.find("questions", {
        query: {
          moduleId: props.moduleId,
          $sort: { createdAt: -1 },
          $skip: items.length,
          $limit: 4
        }
      }).then( r => {
        if (r.data.length > 0) {
          r.data.map( q => props.actions.questions.append(moduleId, q))
        } else {
          setMoreComing(false);
        }

      })
    }
  }, [feathersContext, items.length, moduleId, props.actions.questions, props.moduleId]);

  useEffect(() => {
    feathersContext.get("modules", moduleId)
      .then( r => setModule(r) );
  }, [feathersContext, moduleId]);

  useEffect(() => {
    // INITIAL LOAD OF QUESTIONS
    feathersContext.find("questions", {
      query: {
        moduleId: props.moduleId,
        $sort: { createdAt: -1 },
        $limit: 4
      }
    }).then( r => {
      props.actions.questions.update(moduleId, r.data);
    })

  }, [feathersContext, moduleId, props.actions.questions, props.moduleId]);



  return (
    <div className="outmostContainer">
      <div className="outer-container FullHeight">
        <div className="central folded">
          <div className="QuestionsReportModule Module" id="scrollable">
            <h2>Preguntas del módulo {module.name}</h2>
            <table>
              <thead>
                <tr>
                  <th>Hora</th>
                  <th>Pregunta</th>
                  {/*<th>Acciones</th>*/}
                </tr>
              </thead>
              <tbody>
                {items.map( i => (
                  <tr>
                    <td className="Auto"><Moment format="L LT">{i.createdAt}</Moment></td>
                    <td>{i.content}</td>
                    {/*<td className="Auto">
                      <button className="btn">Activar</button>
                    </td>*/}
                  </tr>
                ))}

              </tbody>
            </table>

            {moreComing && <button className="btn" onClick={loadMore}>Cargar más</button>}

          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    data: {
      questions: state.data.questions
    },
    services: {
      app: state.services.app,
    },
  }),
  (dispatch) => ({
    actions: {
      questions: bindActionCreators(questionsActionCreators, dispatch),
    },
  })
)(Component);