import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App'
import Alert from './components/Alert';
import * as serviceWorker from './serviceWorker';

import moment from "moment"
import "moment/locale/es";
import "moment/locale/pt";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/zh-cn";
import "moment/locale/ca";



import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-displaynames/polyfill'
import '@formatjs/intl-displaynames/locale-data/en'
import '@formatjs/intl-displaynames/locale-data/es'
import '@formatjs/intl-displaynames/locale-data/pt'
import '@formatjs/intl-displaynames/locale-data/fr'
import '@formatjs/intl-displaynames/locale-data/it'
import '@formatjs/intl-displaynames/locale-data/zh'
import '@formatjs/intl-displaynames/locale-data/ca'

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";

moment.defineLocale('zh', {
  parentLocale: 'zh-cn',
  /* */
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        <Alert
          title="Lorem ipsum"
          body="Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum."
          cancelText="Cancelar"
        />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
