import React, { useEffect } from 'react';


import AppLoader from "./components/AppLoader";

import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "./services/app/actions";

import FeathersProvider from "./services/feathers/FeathersProvider";
import { MixPanelProvider } from './services/mixpanel/MixpanelProvider';

import { FormattedMessage, IntlProvider } from "react-intl";
import messages_es from "./translations/es.json";
import messages_ca from "./translations/ca.json";
import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";
import messages_it from "./translations/it.json";
import messages_zh from "./translations/zh.json";


import Hall from "./scenes/Hall2020";
import Room from "./scenes/Room";
import SignIn from "./scenes/SignIn";
import SignUp from "./scenes/SignUp";
import ResetPassword from "./scenes/ResetPassword";
import Meetings from "./scenes/Meetings";
import Chat from "./scenes/Chat";
import Networking from "./scenes/Networking";
import QuestionsReport from "./scenes/QuestionsReport";

import './App.scss';
import AnalyticsWrap from './AnalyticsWrap';


const messages = {
  es: messages_es,
  ca: messages_ca,
  en: messages_en,
  pt: messages_pt,
  fr: messages_fr,
  it: messages_it,
  zh: messages_zh
};

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

function App(props) {

  const slug = window.location.pathname.split("/").filter((p) => p !== "")[0];

  useEffect(() => {

    if (slug !== "") {
      props.actions.app.setSlug(slug);
    }

  }, [props.actions.app, slug]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--theme-primary-color",
      props.manifest.themeColor
    );
    const components = hexToRgb(props.manifest.themeColor);
    if (components) {
      document.documentElement.style.setProperty(
        "--theme-primary-color-light",
        `rgba(${components.r},${components.g},${components.b},0.1)`
      );
      document.documentElement.style.setProperty(
        "--theme-primary-color-hover",
        `rgba(${components.r},${components.g},${components.b},0.85)`
      );
    }
  }, [props.manifest]);

  let lang = 'es';

  if (props.language) lang = props.language;
  else if (navigator.language && props.manifest.langPattern && props.manifest.langPattern.available && props.manifest.langPattern.available.includes(navigator.language.substr(0,2))) {
    lang = navigator.language.substr(0,2);
  } else if (props.manifest.langPattern && props.manifest.langPattern.default) lang = props.manifest.langPattern.default;


  return slug ? (
    <MixPanelProvider slug={slug}>
      <FeathersProvider slug={slug}>
        <IntlProvider locale={lang} messages={messages[lang]}>
          <BrowserRouter>

            <AnalyticsWrap>
              {props.isAuthenticated || !props.manifest.accessOptions.private ?
                <Routes basename={slug}>
                  <Route path="/" element={<Hall />} />
                  <Route path="/:roomId" element={<Room />} />
                  <Route path="signin" element={<SignIn />} />
                  <Route path="signup" element={<SignUp />} />
                  <Route path="reset-password" element={<ResetPassword />} />

                  {props.isAuthenticated &&
                    <React.Fragment>
                      <Route path="meetings" element={<Meetings />} />
                      <Route path="chat" element={<Chat />} />
                      <Route path="networking" element={<Networking />} />
                    </React.Fragment>
                  }
                </Routes>
                :
                <Routes basename={slug}>
                  <Route path="/questions-report/:moduleId" element={<QuestionsReport />} />
                  <Route path="signin" element={<SignIn />} />
                  <Route path="signup" element={<SignUp />} />
                  <Route path="reset-password" element={<ResetPassword />} />
                  <Route path="*" element={<SignIn />} />
                </Routes>
              }
            </AnalyticsWrap>

          </BrowserRouter>
        </IntlProvider>
      </FeathersProvider>

    </MixPanelProvider>
  ) : (
    <h1>No app specified</h1>
  );
}

export default connect(
  (state, ownProps) => ({
    loading: state.services.app.loading,
    manifest: state.services.app.manifest,
    language: state.services.app.language,
    slug: state.services.app.slug,
    isAuthenticated: state.services.app.feathersAuthenticated
  }),
  (dispatch) => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch),
    },
  })
)(App);
