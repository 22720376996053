import React, { useContext, useCallback, useEffect, useState } from "react";

// OWN ROOT COMPONENTS

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import ScaledImage from "../../../../components/ScaledImage";
import PlaceholderAvatar from "../../../../components/PlaceholderAvatar";

// OTHER UTILS
import { useIntl, FormattedMessage } from "react-intl";
import Moment from 'react-moment';


const Conversations = ({ ...props }) => {

  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  const [query, setQuery] = useState("");

  const initialLoad = useCallback(() => {
    feathersContext
      .findConversations();
  }, [feathersContext]);

  useEffect(() => {
    initialLoad()
  }, [initialLoad]);

  const loadMore = useCallback(
    (info) => {
      const items = props.data.conversations.items;
      if (items.length < props.data.conversations.total) {
        feathersContext
          .loadMoreConversations({
            query: {
              $skip: items.length,
            },
          });
      }
    },
    [feathersContext, props.data.conversations.items, props.data.conversations.total]
  );

    console.log("conversations", props.data.conversations)
  return (
    <div className={`Module Meetings ${props.className}`}>
      {props.customHeader ? props.customHeader() : null}
      <div>
        <div className={`User ${props.meetingsFilter == 'active' ? 'Active' : ''}`} onClick={() => props.setMeetingsFilter('active')}><FormattedMessage id="networking.agenda" /></div>
        <div className={`User ${props.meetingsFilter == 'cancelled' ? 'Active' : ''}`} onClick={() => props.setMeetingsFilter('cancelled')}><FormattedMessage id="networking.rejected" /></div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    data: {
      conversations: state.data.conversations
    },
    services: {
      app: state.services.app
    }
  }),
  null
)(Conversations);