import React, { Fragment, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import $ from "jquery";

import { useIntl, FormattedMessage } from "react-intl";

import moment from "moment";

export const foldUnfold = (e, what = false) => {
  e.preventDefault();
  if ($(".curtain").hasClass("folded")) {
    $(".central, .curtain, .sidebar").removeClass("folded");
  } else {
    if (!what || $(`.inner.${what}`).is(":visible")) {
      $(".central, .curtain, .sidebar").addClass("folded");
    }
  }

  if (what) {
    $(".inner").hide();
    $(`.inner.${what}`).show();
  }
};

export default (props) => {

  const intl = useIntl();
  const lang = intl.locale;


  const hours = props.appointments.reduce((hours, va) => {
    const ref = moment(va.startDate).locale(lang).format("YYYYMMDDHH");
    return Object.keys(hours).includes(ref)
      ? { ...hours, [ref]: [...hours[ref], va] }
      : { ...hours, [ref]: [va] };
  }, {});

  const days = Object.keys(hours).reduce((days, h) => {
    const ref = h.substr(0, 8);
    return Object.keys(days).includes(ref)
      ? { ...days, [ref]: { ...days[ref], [h]: hours[h] } }
      : { ...days, [ref]: { [h]: hours[h] } };
  }, {});

  const isCurrent = (va) => {
    const starts = moment(va.startDate);
    const ends = moment(va.endDate);
    console.log("Starts", starts);
    console.log("Ends", ends);
    console.log("Moment", moment());
    return moment().isBetween(starts, ends);
  };



  const [currentDay, setCurrentDay] = useState(null);

  useEffect(() => {
    if (!currentDay) {
      const baseDays = Object.keys(days).filter((d) =>
        moment(d).isSame(moment(), "day")
      );
      const baseDay = baseDays[0] || Object.keys(days)[0];
      setCurrentDay(baseDay)
    }
  }, [currentDay, days])

  const handleDayChange = (d) => {
    setCurrentDay(d);
  };

  const rooms = props.rooms;


  console.log("ROOMS", rooms);

  return (
    <Fragment>
      <div class="curtainOverlay folded" onClick={(e) => foldUnfold(e)}></div>
      <div className="curtain folded">
        <div class="inner agenda">
          <div className="top">
            <h3>
              <FormattedMessage id="agendaWidget.title" />
            </h3>
            <a href="#" onClick={(e) => foldUnfold(e)}>
              <span class="icon-md-arrow-left"></span>
            </a>
          </div>
          <div className="daySelector">
            {Object.keys(days).map((d) => (
              <button
                type="button"
                onClick={(e) => handleDayChange(d)}
                className={`day ${d === currentDay ? "active" : ""}`}
              >
                {moment(d).locale(lang).format("D MMM")}
              </button>
            ))}
          </div>
          {currentDay && (
            <div className="day" data-day={currentDay}>
              {Object.keys(days[currentDay]).map((h) => (
                <div className="hour">
                  <div className="header">
                    {moment(h, "YYYYMMDDHH").locale(lang).format("LT")}
                  </div>
                  <div className="appointments">
                    {days[currentDay][h].map((va) => {
                      const room = rooms.filter(
                        (r) => String(r._id) === String(va.roomId)
                      )[0];
                      return (
                        <Link
                          to={room ? `/${props.slug}/${room.slug}` : ''}
                          className={`appointment ${
                            isCurrent(va) &&
                            props.room &&
                            String(props.room._id) === String(va.roomId)
                              ? "active"
                              : ""
                          }`}
                        >
                          <div className="firstCol">
                            <h4>{va.title && va.title[lang]}</h4>
                            <span
                              className={`time ${
                                isCurrent(va) ? "active" : ""
                              }`}
                            >
                              {moment(va.startDate).locale(lang).format("LT")} -{" "}
                              {moment(va.endDate)
                                .locale(lang)
                                .format("LT")}
                            </span>
                          </div>
                          {rooms
                            .map((r) => String(r._id))
                            .includes(String(va.roomId)) && (
                            <span
                              className={`room ${
                                props.room &&
                                String(props.room._id) === String(va.roomId)
                                  ? "active"
                                  : ""
                              }`}
                            >
                              {room.title[lang]}
                            </span>
                          )}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div class="inner rooms">
          <div className="top">
            <h3>
              <FormattedMessage id="roomsWidget.title" />
            </h3>
            <a href="#" onClick={(e) => foldUnfold(e)}>
              <span class="icon-md-arrow-left"></span>
            </a>
          </div>
          {(rooms||[]).filter((r) => !r.access || !r.access.private)
            .map((r) => (
              <a
                className={`btn ${
                  props.room && props.room.slug === r.slug ? "active" : ""
                }`}
                href={`/${props.slug}/${r.slug}`}
              >
                {r.title[lang]}
              </a>
            ))}
        </div>
      </div>
    </Fragment>
  );
};
