import React from "react";

// OWN ROOT COMPONENTS

// STYLING
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default ({
  user,
  themeColor = "#333",
  textStyle = {},
  style,
  ...props
}) => {
  return user.firstName ? (
    <div
      style={{ ...styles.container, ...style, backgroundColor: themeColor }}
      {...props}
    >
      <span
        style={{ color: "#fff", ...textStyle }}
      >{`${user.firstName.substr(0, 1).toUpperCase()}${user.lastName
        .substr(0, 1)
        .toUpperCase()}`}</span>
    </div>
  ) : null;
};
