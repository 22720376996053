import React, { useEffect, useState, useContext } from 'react';
import jQuery from 'jquery';
import { FeathersContext } from '../../services/feathers/FeathersProvider';



const NUMBER_ITEMS = 15;
const TOTAL_DURATION = 2000;

export default props => {

  const feathersContext = useContext(FeathersContext);

  const [active, setActive] = useState(null);
  const [reactions, setReactions] = useState(null)

  const refreshReactions = (room) => {
    const {
      likes,
      laughs,
      claps,
      thumbs,
      customReactions
    } = room;

    if (likes > reactions.likes) renderReaction("like");
    if (laughs > reactions.laughs) renderReaction("laugh");
    if (claps > reactions.claps) renderReaction("clap");
    if (thumbs > reactions.thumbs) renderReaction("thumb");
    if (customReactions > reactions.customReactions) renderReaction("customReaction");

    setReactions({
      likes,
      laughs,
      claps,
      thumbs,
      customReactions
    })

  };

  useEffect(() => {

    if (props.room && props.room._id && reactions === null) {
      setReactions({
        likes: props.room.likes,
        laughs: props.room.laughs,
        claps: props.room.claps,
        thumbs: props.room.thumbs,
        customReactions: props.room.customReactions
      })
    }
  }, [props.room]);

  useEffect(() => {

    feathersContext.client.service('reactions').on("created", refreshReactions);

    return () => {

      feathersContext.client.service('reactions').off("created", refreshReactions);
    };
  }, [feathersContext.client, refreshReactions]);

  const renderReaction = (reaction) => {
    let icon = "";

    if (reaction == 'like') icon = '❤️';
    if (reaction == 'laugh') icon = '😂';
    if (reaction == 'clap') icon = '👏';
    if (reaction == 'thumb') icon = '👍';
    if (reaction == 'customReaction') icon = '👎';

    for (let i = 0; i < NUMBER_ITEMS; i++) {
      jQuery('.reactions').append(`<div style='left: ${Math.floor(Math.random() * (20 - 0 + 1) + 0)}%;' class='emoji item-${i}'>${icon}</div>`);
    }

    let counter = 0;
    const interval = setInterval((() => {

      jQuery(`.emoji.item-${counter}`)
        .addClass("animated");

      counter++;

      if (counter > NUMBER_ITEMS) {
        clearInterval(interval);
        //jQuery('.reactions').empty();
      }
    }), TOTAL_DURATION / NUMBER_ITEMS)
  };

  const pressEmoji = (reaction) => {

    feathersContext.client.service('reactions').create({
      roomId: props.room._id,
      reaction
    })

    //renderReaction(reaction);
  };

  return (
    <React.Fragment>
      <div className="embed llucana">
        <iframe
          title="External"
          src={props.embedRef}
          scrolling="no"
          frameborder="0"
          allow="autoplay; fullscreen; camera; microphone; display-capture"
          allowFullScreen
          webkitallowfullscreen
          mozallowfullscreen
        // style="margin-top:-72px;height:800px;"
        ></iframe>
        <div className='reactions'>
          {/* <div className='emoji'>❤️</div> */}
        </div>

      </div>
      {props.room && props.room.enableReactions ?
        <div className='emoji-wrap'>
          <div className='emojis'>
            <div onClick={() => pressEmoji('like')} className='emoji'>
              ❤️
            </div>
            <div onClick={() => pressEmoji('laugh')} className='emoji'>
              😂
            </div>
            <div onClick={() => pressEmoji('clap')} className='emoji'>
              👏
            </div>
            <div onClick={() => pressEmoji('thumb')} className='emoji'>
              👍
            </div>
            <div onClick={() => pressEmoji('customReaction')} className='emoji'>
              👎
            </div>
          </div>
          <div className='counter'>
            <div className='emoji'>
              <span className='icon'>❤️</span>
              <span className='count'>{reactions?.likes||0}</span>
            </div>
            <div className='emoji'>
              <span className='icon'>😂</span>
              <span className='count'>{reactions?.laughs || 0}</span>
            </div>
            <div className='emoji'>
              <span className='icon'>👏</span>
              <span className='count'>{reactions?.claps || 0}</span>
            </div>
            <div className='emoji'>
              <span className='icon'>👍</span>
              <span className='count'>{reactions?.thumbs || 0}</span>
            </div>
            <div className='emoji'>
              <span className='icon'>👎</span>
              <span className='count'>{reactions?.customReactions || 0}</span>
            </div>
          </div>
        </div>
      : null}
    </React.Fragment>

  );
}