import React, { useContext, useCallback, useEffect, useState } from "react";

// OWN ROOT COMPONENTS

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import ScaledImage from "../../../../components/ScaledImage";
import PlaceholderAvatar from "../../../../components/PlaceholderAvatar";

// OTHER UTILS
import Moment from 'react-moment';
import { useIntl, FormattedMessage } from "react-intl";


const Conversations = ({ ...props }) => {

  const intl = useIntl();
  const lang = intl.locale;

  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  const [query, setQuery] = useState("");

  const initialLoad = useCallback(() => {
    feathersContext
      .findConversations();
  }, [feathersContext]);

  useEffect(() => {
    initialLoad()
  }, [initialLoad]);

  const loadMore = useCallback(
    (info) => {
      const items = props.data.conversations.items;
      if (items.length < props.data.conversations.total) {
        feathersContext
          .loadMoreConversations({
            query: {
              $skip: items.length,
            },
          });
      }
    },
    [feathersContext, props.data.conversations.items, props.data.conversations.total]
  );

    console.log("conversations", props.data.conversations)
  return (
    <div className={`Module Conversations ${props.className}`}>
      {!props.omitHeader &&
        <div className="Header">
          <span class="icon-md-template"></span>
          <h3><FormattedMessage id="networking.conversationsTitle" /></h3>
        </div>
      }
      {props.customHeader ? props.customHeader() : null}
      {props.data.conversations.items.map((c) => (
        <div onClick={() => props.conversationClicked(c)} className={`User ${props.activeConversation && props.activeConversation._id === c._id ? 'Active' : null}`}>
          {c.counterpartUser.picture ? (
            <ScaledImage image={c.counterpartUser.picture} className="Avatar" />
          ) : (
            <PlaceholderAvatar
              user={c.counterpartUser}
              themeColor={props.services.app.manifest.themeColor}
              className="Avatar"
            />
          )}
          <div className="UserText">
            <h5>
              {c.counterpartUser.firstName} {c.counterpartUser.lastName}
            </h5>
            <span>{c.lastMessage.text}</span>
          </div>
          <div className="Meta">
            <span className="Time"><Moment locale={lang} fromNow ago>{c.lastMessage.createdAt}</Moment></span>
            {c.totalUnread > 0 &&
              <span className="Unread">{c.totalUnread}</span>
            }

          </div>
        </div>
      ))}
      {props.data.conversations.items.length < props.data.conversations.total &&
        <button onClick={loadMore} className="btn btn-outline LoadMore"><FormattedMessage id="networking.loadMore" /></button>
      }
    </div>
  );
};

export default connect(
  (state) => ({
    data: {
      conversations: state.data.conversations
    },
    services: {
      app: state.services.app
    }
  }),
  null
)(Conversations);