import React, { useState, useEffect, useContext, Fragment } from 'react';

import BelongModal from '../../../../components/BelongModal';

import { FeathersContext } from "../../../../services/feathers/FeathersProvider";

import Select from 'react-select'
import moment from 'moment';
import { useIntl, FormattedMessage } from "react-intl";

export default props => {
  const intl = useIntl();

  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  const { modalVisible, setModalVisible, lang } = props;

  const [meeting, setMeeting] = useState({});
  const [users, setUsers] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [days, setDays] = useState([]);
  const [slots, setSlots] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [slot, setSlot] = useState(null);

  useEffect(() => {
    feathersContext.find("users", { query: { $limit: -1 } }).then( r => {
      setUsers(r);
    })
  }, [feathersContext]);

  useEffect(() => {
    setMeeting(props.meeting);

    if (users && props.meeting && props.meeting.participants) {

      setParticipants(props.meeting.participants.filter(p => p.userId != props.userId).map( p => {
        const found = users.filter(u => u._id.toString() == p.userId.toString());
        const tu = found.length > 0 ? found[0] : {};
        return {
          value: p.userId,
          label: `${tu.firstName} ${tu.lastName}`
        };
      }));


    }

  }, [feathersContext, users, props.meeting, props.userId]);

  useEffect(() => {
    feathersContext.find("slots", {
        query: {
          userIds: participants.map(p => p.value)
        }
      }).then(r => {
        setSlots(r);
      }).catch(e => {
        alert(JSON.stringify(e))
      })
  }, [feathersContext, participants]);

  useEffect(() => {
    const ld = [];
    slots.map(s => {
      if (!ld.some(d => d.value.format('YYYYMMDD') == moment(s.date).format('YYYYMMDD'))) {
        ld.push({
          value: moment(s.date),
          label: moment(s.date).locale(lang).format('dddd DD')
        })
      }
    })
    setDays(ld);
  }, [lang, slots]);

  const submitMeeting = () => {
    if (meeting._id) {
      const newMeeting = {
        ...meeting,
        participants: [
          { userId: props.userId, isStaff: true, attending: 'yes' },
          ...participants.map(p => ({ userId: p.value, isStaff: false, attending: 'pending' }))
        ],
      }
      feathersContext.patchAndUpdate("meetings", meeting._id, newMeeting).then( r => {
        //props.onSuccess();
        setMeeting({});
        setModalVisible(false);
      }).catch( e => {
        alert(JSON.stringify(e))
      })
    }
    else alert("Create")
  };

  const participantSetter = (options) => {
    setParticipants(options);
    setMeeting({ ...meeting, date: null })
    setSelectedDay(null);
  };

  const isValid = () => {
    return meeting.title && meeting.title != "" && meeting.date && participants.length > 0;
  }

  return (
    <BelongModal
        visible={modalVisible}
        header={
          <React.Fragment>
            {meeting._id ? <h2><FormattedMessage id="networking.form.editMeeting" /></h2> : <h2><FormattedMessage id="networking.form.newMeeting" /></h2>}
          </React.Fragment>
        }
        body={
          <div className="MeetingForm">
            <div className="Field">
              <Select
                onChange={(elem) => participantSetter(elem)}
                value={participants}
                isMulti
                className="Select"
                options={users.map(u => ({ value: u._id, label: `${u.firstName} ${u.lastName}` }))}
                placeholder={intl.formatMessage({ id: "networking.form.selectPeople" })}
              />
            </div>
            <div className="Field">
              <label><FormattedMessage id="networking.form.meetingTitle" /></label>
              <input type="text" value={meeting.title} onChange={(e) => setMeeting({ ...meeting, title: e.target.value })} />
            </div>
            <div className="Field">
              <label><FormattedMessage id="networking.form.message" /></label>
              <textarea rows={4} value={meeting.message} onChange={(e) => setMeeting({ ...meeting, message: e.target.value })}></textarea>
            </div>
            {meeting.date ?
              <div className="Field">
                <label><FormattedMessage id="networking.form.date" /></label>
                <p>{moment(meeting.date).locale(lang).format("dddd, LT")} -{" "}
                              {moment(meeting.date)
                                .locale(lang)
                                .format("LT")} <a href="#" onClick={() => {
                                  setMeeting({ ...meeting, date: null });
                                  setSelectedDay(null);
                                }}><FormattedMessage id="networking.form.modify" /></a></p>
              </div>
              :
              <React.Fragment>
                <div className="Field">
                  <label><FormattedMessage id="networking.form.date" /></label>
                  <Select
                    onChange={(elem) => {
                      setSelectedDay(elem.value)
                    }}
                    className="Select"
                    options={days}
                    placeholder={intl.formatMessage({ id: "networking.form.selectDay" })}
                  />
                </div>
                {selectedDay !== null &&
                  <div className="Field Hours">
                    <label><FormattedMessage id="networking.form.slots" /></label>
                    <ul>
                      {slots.filter(s => selectedDay.isSame(s.date, 'day')).map(s => (
                        <li
                          onClick={() => {
                            if(s.available) {
                              setMeeting({ ...meeting, date: s.date })
                            }
                          }}
                          className={`${slot && slot.isSame(s.date, 'minute') ? 'Selected' : ''} ${s.available ? '' : 'Unavailable'}`}
                        >
                          {moment(s.date).format("HH:mm")} - {moment(s.date).add(s.interval, 'minutes').format("HH:mm")}
                        </li>
                      ))}
                    </ul>
                  </div>
                }
              </React.Fragment>
            }

          </div>
        }
        footer={
          <React.Fragment>
            <button onClick={() => {
              setModalVisible(false);
            }} className="btn btn-outline"><FormattedMessage id="networking.form.cancel" /></button>
            <button disabled={!isValid()} onClick={() => submitMeeting() } className="btn"><FormattedMessage id="networking.form.requestMeeting" /></button>
          </React.Fragment>
        }
      />
  );
}