import { combineReducers } from "redux";
import appointmentsReducer from "./appointments/reducer";
import roomsReducer from "./rooms/reducer";
import usersReducer from "./users/reducer";
import meetingsReducer from "./meetings/reducer";
import questionsReducer from "./questions/reducer";
import surveyBlocksReducer from "./surveyBlocks/reducer";
import conversationsReducer from "./conversations/reducer";
import messagesReducer from "./messages/reducer";

export default combineReducers({
  appointments: appointmentsReducer,
  rooms: roomsReducer,
  users: usersReducer,
  meetings: meetingsReducer,
  questions: questionsReducer,
  surveyBlocks: surveyBlocksReducer,
  conversations: conversationsReducer,
  messages: messagesReducer
});
