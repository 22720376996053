import React, { Fragment } from 'react';

// OWN ROOT COMPONENTS


// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";

// OTHER UTILS

// STYLING
const styles = {};

const Component = ({visible, header, body, footer}) => {

  return (
    <Fragment>
    <div className={`ModalOverlay ${!visible && 'hidden'}`}></div>
    {visible &&
      <div className="Modal">
        <div className="Header">
          {header}
        </div>
        <div className="Body">
          {body}
        </div>
        <div className="Footer">
          {footer}
        </div>
      </div>
    }
    </Fragment>
  );
}

export default connect(
  (state) => ({
    services: {
      app: state.services.app,
    },
  }),
  null
)(Component);