import React, { useState, useEffect, useContext } from "react";

import WidgetLoader from '../WidgetLoader';

import Select from 'react-select';

import { FeathersContext } from "../../services/feathers/FeathersProvider";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../../services/app/actions";
import * as questionsActionCreators from '../../data/questions/actions';

import { useIntl, FormattedMessage } from "react-intl";

const MAX_CHARS = 200;

const amplitude = require("amplitude-js");

function Question(props) {
  const intl = useIntl();

  return (
    <li class="question">
      <div className="person">
        {props.question.user ? (
          props.question.user.picture_thumb ?
          <img src={props.question.user.picture_thumb} class="thumb" />
          :
          <span class="basic-thumb">
            {props.question.user && props.question.user.firstName && props.question.user.firstName.substr(0, 1)}
            {props.question.user && props.question.user.lastName && props.question.user.lastName.substr(0, 1)}
          </span>
        ) : (
          <span class="thumb"></span>
        )}
        <span className="name">{props.question.user && props.question.user.firstName ? props.question.user.firstName : intl.formatMessage({ id: "questionsWidget.anonFirstName" })} {props.question.user && props.question.user.lastName ? props.question.user.lastName : intl.formatMessage({ id: "questionsWidget.anonLastName" })}</span>
      </div>
      <span class="content">{props.question.content}</span>
    </li>
  );
}



function QuestionsWidget(props) {
  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  const intl = useIntl();

  const { moduleId, socket, themeColor } = props;

  const [focus, setFocus] = useState(false);

  const [questionText, setQuestionText] = useState('');
  const [questionTopic, setQuestionTopic] = useState(null);

  useEffect(() => {
    // INITIAL LOAD OF QUESTIONS
    feathersContext.find("questions", {
      query: {
        moduleId: props.moduleId,
        $sort: { createdAt: -1 },
      }
    }).then( r => {
      props.actions.questions.update(props.moduleId, r.data);
    })
    // axios
    //   .get("https://staging.ubelong.io/padcelona-demo/api/v2/questions.json")
    //   .then(function (response) {
    //     setTimeout(() => {
    //       setQuestions(response.data.questions);
    //       setLoading(false);
    //     }, 3000);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //     setLoading(false);
    //   });

  }, [feathersContext, feathersContext.find, moduleId, props.actions.questions, props.moduleId, socket]);

  const handleQuestionChange = (e) => {
    if (e.target.value.length <= MAX_CHARS) {
      setQuestionText(e.target.value);
    }
  };

  const onTopicSelect = (option) => {
    setQuestionTopic(option);
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (questionText.trim().length > 0) {
      feathersContext.create("questions", {
        topicId: questionTopic ? questionTopic.value : null,
        content: questionText,
        moduleId: props.moduleId
      }).then( r => {
        setQuestionText("");
        setQuestionTopic(null);
        if(!props.showsFeed) {
          props.actions.app.showAlert({
            modal: true,
            title: intl.formatMessage({ id: "questionsWidget.alertTitle" }),
            body: intl.formatMessage({ id: "questionsWidget.alertBody" }),
            okText: intl.formatMessage({ id: "questionsWidget.alertOk" })
          });
        }
      });
    }
  };

  const lang = intl.locale;

  return (
    <div class="questions-wrap marginated">
      <div class="title">
        <span className={`icon-md-${props.module.icon}`}></span>
        <h3>
          {props.module.title && props.module.title[lang]}
        </h3>
      </div>

      {props.loading ? (
        <WidgetLoader themeColor={themeColor} />
      ) : (
        <div>
          {
            !props.showsFeed ? (
              props.module.hint && props.module.hint[lang] && props.module.hint[lang] != '' ? <div class="no-questions">
                {props.module.hint && props.module.hint[lang]}
              </div> : null
            ) : (
              <ul class="questions">
                {props.questions
                  .slice(0)

                  .map((question) => (
                    <Question key={question.id} question={question} />
                  ))}
              </ul>
            )
          }

          <form class="questions-input-wrap pill" onSubmit={handleSubmit}>
            <h4>{intl.formatMessage({ id: props.showsFeed ? 'questionsWidget.chatTitle' : 'questionsWidget.questionTitle' })}</h4>
            {props.module.topics && props.module.topics.length > 0 ?
              <Select
                onChange={onTopicSelect}
                value={questionTopic}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    background: "#F9F9FB",
                    // match with the menu
                    borderWidth: 0,
                    borderRadius: 5,
                    marginBottom: 10,
                    paddingLeft: 8,
                    fontSize: 13,
                    // Overwrittes the different states of border
                    borderColor: 'transparent',
                    // Removes weird border around container
                    boxShadow: state.isFocused ? null : null,
                  }),
                  placeholder: () => ({
                    fontSize: 13,
                    color: '#4B4B4B',

                  })
                }}
                placeholder={props.module.topicsPlaceholder && props.module.topicsPlaceholder[lang]}
                options={props.module.topics.map(t => ({ value: t._id, label: t.title[lang] }))}
              />
            : null}
            <input
              type="text"
              class={`questions-input ${
                focus || questionText.length > 0 ? "active" : null
              }`}
              onChange={handleQuestionChange}
              value={questionText}
              placeholder={intl.formatMessage({ id: props.showsFeed ? 'questionsWidget.chat' : 'questionsWidget.ask' })}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
            <div class="questions-input-footer">
              <span>
                {questionText.length}/{MAX_CHARS}
              </span>
              <button
                type="submit"
                class={focus || questionText.length > 0 ? "active" : null}
              >
                <span class="icon icon-sm-send"></span>
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}


export default connect(
  (state, ownProps) => ({
    manifest: state.services.app.manifest,
    loading: state.data.questions.modules[ownProps.moduleId]
      ? state.data.questions.modules[ownProps.moduleId].loading
      : true,
    questions: state.data.questions.modules[ownProps.moduleId]
      ? state.data.questions.modules[ownProps.moduleId].items
      : [],
  }),
  (dispatch) => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch),
      questions: bindActionCreators(questionsActionCreators, dispatch),
    },
  })
)(QuestionsWidget);
