import React from "react";

// OWN ROOT COMPONENTS
import LlucanaView from '../../../../components/LlucanaView'

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";

// OTHER UTILS
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
const htmlParser = require('react-markdown/plugins/html-parser')

const _MARKDOWN = `* Administración Portuaria
* Agente de aduanas
* Asociación empresarial
* Consignatorio
* Formación
* Importador / Exportador
* Institución pública
* Naviera / Armador
* Terminal / Empresa estibadora
* TIC / Consultoría
* Transitario / Operador logístico
* Otros
`;

const MARKDOWN = `
* Agente de aduanas
* Asociación empresarial
* Consignatorio
* Formación
* Importador / Exportador
* Institución pública
* Naviera / Armador
* Terminal / Empresa estibadora
* TIC / Consultoría
* Transitario / Operador logístico
* Otros
This Markdown contains <a href="https://en.wikipedia.org/wiki/HTML">HTML</a>, and will require the <code>html-parser</code> AST plugin to be loaded, in addition to setting the <code class="prop">allowDangerousHtml</code> property to false.
`

export default ({ flex, embedType, embedRef, description }) => {

  const parse = htmlParser({
    isValidNode: (node) => node.type !== 'script',
    processingInstructions: [/* ... */]
  })


  return (
    <div className="Module Static" style={ flex ? { flex } : {} }>
      {embedType == 'iframe' && <div className="embed-outer"><LlucanaView embedRef={embedRef} /></div>}
      <ReactMarkdown children={description} htmlParser={parse} skipHtml={false} allowDangerousHtml />
    </div>
  );
};
