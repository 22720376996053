import React, {} from 'react';

// OWN ROOT COMPONENTS
import ScaledImage from '../../../../../../components/ScaledImage';
import PlaceholderAvatar from '../../../../../../components/PlaceholderAvatar';


// OTHER UTILS

// STYLING
const styles = {};

export default ({ conversationSender, message, mine, ...props }) => {

  return (
    <div className={`Message ${mine ? 'Mine' : 'NotMine'}`} key={message._id} >
      {mine ?
      <React.Fragment>
        <span className="Text">{message.text}</span>
      </React.Fragment>
      :
      <React.Fragment>
        {conversationSender.picture ? (
          <ScaledImage image={conversationSender.picture} className="Avatar" />
        ) : (
          <PlaceholderAvatar
            user={conversationSender}
            themeColor={props.themeColor}
            className="Avatar"
          />
        )}
        <span className="Text">{message.text}</span>
      </React.Fragment>
      }
    </div>
  );
}
