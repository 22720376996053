import React, {useEffect} from 'react';

export default props => {

  // useEffect(() => {
  //   const elm = document.querySelector("whereby-embed");
  //   elm.addEventListener("leave", () => {
  //     props.onDisconnect();
  //   })
  // }, [props])

  return <div className="embed whereby" style={ props.fullScreen ? { position: 'absolute', width: '100%', height: '100%', paddingTop: 0} : {} }>
    <whereby-embed
      embed
      subdomain="ubelong"
      room={props.room.whereby.roomUrl}
      minimal
      help="off"
      ageWarning="off"
      intercom="off"
      precallReview="off"
      timer="off"
      chat="off"
      audio="off"
      video="off"
      leaveButton="off"
    ></whereby-embed>
    <button style={{ position: 'absolute', right: 10, bottom: 10 }} className="btn" type="button" onClick={props.toggleFullScreen}>
      <span className={ props.fullScreen ? 'icon-md-zoom-out toggleFull' : 'icon-md-zoom-in toggleFull' }></span>
    </button>
  </div>
}