import React, { useEffect, useState, useContext, Fragment } from "react";

import TopBar from "../../components/TopBar";
import { scaledImageUrl } from '../../components/ScaledImage'

import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../../services/app/actions";

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const amplitude = require("amplitude-js");

function App(props) {

  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);
  const intl = useIntl();
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(false);
  const [inviteCode, setInviteCode] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(false);

  const lang = intl.locale;

  const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  useEffect(() => {
    amplitude.getInstance().logEvent("pageView", { name: "SignIn" });
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--theme-primary-color",
      props.manifest.themeColor
    );
    const components = hexToRgb(props.manifest.themeColor);
    if (components) {
      document.documentElement.style.setProperty(
        "--theme-primary-color-light",
        `rgba(${components.r},${components.g},${components.b},0.1)`
      );
      document.documentElement.style.setProperty(
        "--theme-primary-color-hover",
        `rgba(${components.r},${components.g},${components.b},0.85)`
      );
    }
  }, [props.manifest]);

  const showConditions = e => {
    e.preventDefault();
    props.actions.app.showAlert({
      modal: true,
      html: props.manifest.terms[lang],
      okText: intl.formatMessage({ id: "signUp.termsClose" })
    });

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (terms) {
      setSubmitting(true);
      const data = {
        email,
        firstName,
        lastName,
        password,
        tcAccepted: true
      };

      if (props.manifest.accessOptions.inviteRequired) {
        data.inviteCode = inviteCode
      }

      feathersContext.create("users", data)
        .then(() => {
          feathersContext.authenticate(props.slug, email, password)
          .then(() => {
            //navigate(`/${props.slug}/`);
          })
          .catch((e) => {
            navigate(`/${props.slug}/signin`);
          });
        })
        .catch((e) => {
          setSubmitting(false);
          console.log(e.errors);
          //setErrors(e.errors);
          // alert(Object.keys(e.errors).map(k => `- ${renderError(e.errors[k])}`).join('<br>'));
          if (e.errors && e.errors.inviteCode && e.errors.inviteCode == 'Already used') {
            props.actions.app.showAlert({
              modal: true,
              title: intl.formatMessage({ id: "signUp.inviteUsedTitle" }),
              bodyElement: <li>{intl.formatMessage({ id: "signUp.inviteUsedBody" })}</li>,
              okText: intl.formatMessage({ id: "signUp.termsClose" })
            });
          } else if (e.errors && e.errors.inviteCode && e.errors.inviteCode == 'Not found') {
            props.actions.app.showAlert({
              modal: true,
              title: intl.formatMessage({ id: "signUp.wrongInviteTitle" }),
              bodyElement: <li>{intl.formatMessage({ id: "signUp.wrongInviteBody" })}</li>,
              okText: intl.formatMessage({ id: "signUp.termsClose" })
            });
          } else {
            props.actions.app.showAlert({
              modal: true,
              title: 'Error de validación',
              bodyElement: Object.keys(e.errors).map((k) => (
                <li key={k}>{renderError(e.errors[k])}</li>
              )),
              okText: intl.formatMessage({ id: "signUp.termsClose" })
            });
          }

        });
    } else {
      props.actions.app.showAlert({
        modal: true,
        title: 'Aceptación obligatoria',
        body: 'Debes aceptar los términos y condiciones para registrarte.',
        okText: intl.formatMessage({ id: "signUp.termsClose" })
      });
    }

  };

  useEffect(() => {
    if (props.feathersAuthenticated) navigate(`/${props.slug}/`)
  }, [navigate, props.feathersAuthenticated, props.slug]);

  const renderError = e => {
    let message = 'El campo ';
    if (e == 'value') {
      return `${intl.formatMessage({ id: "signUp.emailValidation" })} ${intl.formatMessage({ id: "signUp.existsValidation" })}`;
    } else {
      return `${intl.formatMessage({ id: `signUp.${e.path}Validation` })} ${intl.formatMessage({ id: `signUp.${e.kind}Validation` })}`;
    }

  }

  const { manifest, slug } = props;



  return (
    props.manifest.accessOptions.loginable && (
      <div
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column", backgroundColor: props.manifest.themeColor }}
      >
        <TopBar />
        <div className="empty-container" style={{  }}>
          <div className="login">
            <div
              className="logo"
              style={{
                backgroundImage: `url(${props.manifest.logo})`,
              }}
            ></div>
            <h2>
              <FormattedMessage id="signUp.title" />
            </h2>
            <h3>{manifest.title && manifest.title[lang]}</h3>
            <form>
              <div className="pill login-form">
                {props.manifest.accessOptions.inviteRequired ?
                  <input
                    autofocus
                    type="text"
                    placeholder={intl.formatMessage({ id: "signUp.inviteCode" })}
                    value={inviteCode}
                    onChange={(e) => setInviteCode(e.target.value)}
                  />
                  : null
                }
                <input
                  autofocus
                  type="text"
                  placeholder={intl.formatMessage({ id: "signUp.email" })}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="text"
                  placeholder={intl.formatMessage({ id: "signUp.firstName" })}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder={intl.formatMessage({ id: "signUp.lastName" })}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <input
                  type="password"
                  required
                  placeholder={intl.formatMessage({ id: "signUp.password" })}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <label className="help">
                <input
                  type="checkbox"
                  onChange={(e) => setTerms(e.target.checked)}
                />{" "}
                <FormattedMessage id="signUp.accept" />{" "}
                <a href="#" onClick={showConditions}>
                  <FormattedMessage id="signUp.terms" />
                </a>
              </label>
              {/*<p className="help right">
                <a
                  target="_blank"
                  href={`https://www.ubelong.io/${props.slug}/users/password/new?locale=${lang}`}
                >
                  <FormattedMessage id="signIn.forgot" />
                </a>
              </p>*/}
              {errors && !submitting && (
                <div className="message-box show">
                  <ul>
                    {Object.keys(errors).map((k) => (
                      <li key={k}>{renderError(errors[k])}</li>
                    ))}
                  </ul>
                </div>
              )}

              <button
                disabled={submitting}
                onClick={handleSubmit}
                className="btn btn-block btn-lg"
              >
                {submitting
                  ? intl.formatMessage({ id: "signUp.submitting" })
                  : intl.formatMessage({ id: "signUp.submit" })}
              </button>
              {props.manifest.accessOptions.loginable && (
                <p className="help">
                  <FormattedMessage id="signUp.accountAlready" />{" "}
                  <Link to={`/${props.slug}/signin`}>
                    <FormattedMessage id="signUp.logIn" />
                  </Link>
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    )
  );
}

export default connect(
  (state, ownProps) => ({
    manifest: state.services.app.manifest,
    slug: state.services.app.slug,
    feathersAuthenticated: state.services.app.feathersAuthenticated,
  }),
  (dispatch) => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch),
    },
  })
)(App);
