import * as actionTypes from "./actionTypes";

export const update = (items, total) => ({
  type: actionTypes.UPDATE,
  items,
  total,
});

export const append = (items, total) => ({
  type: actionTypes.APPEND,
  items,
  total,
});

export const updateOne = (item) => ({
  type: actionTypes.UPDATE_ONE,
  item,
});

export const loading = () => ({
  type: actionTypes.LOADING,
});

export const empty = () => ({
  type: actionTypes.EMPTY,
});
