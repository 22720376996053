import React from "react";

// OWN ROOT COMPONENTS
import { scaledImageUrl } from '../../../../components/ScaledImage';

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";

// OTHER UTILS
import { Link } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";

export default ({ rooms, flex, manifest }) => {
  const intl = useIntl();
  const lang = intl.locale;

  return (
    <div className="Module Rooms" style={ flex ? { flex } : {} }>
      <div className="Header">
        <span class="icon-md-template"></span>
        <h3><FormattedMessage id="leftBar.rooms" /></h3>
      </div>
      {(rooms||[]).filter(r => !r.access || !r.access.private ).map((r) => (
        r.image ?
          <Link to={r.slug} className="Room Image" style={{ backgroundImage: `url(${r.image})` }}>
            <div className="RoomInner">
              {!r.titleHidden && <h4>{r.title[lang]}</h4> }
            </div>
          </Link>
        :
          <Link to={r.slug} className="Room NoImage">
            <div className="RoomInner">
              {!r.titleHidden && <h4>{r.title[lang]}</h4> }
            </div>
          </Link>
      ))}
    </div>
  );
};

