const initialState = {
  loading: false,
  modules: {},
};

export default (actionTypes) => (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE:
      console.log(`I am update of action ${action.type}`, action.items)
      return {
        ...state,
        modules: {
          ...state.modules,
          [action.moduleId]: { loading: false, items: action.items },
        },
      };
    case actionTypes.APPEND:
      return {
        ...state,
        modules: {
          ...state.modules,
          [action.moduleId]: {
            loading: false,
            items: [
              ...(state.modules[action.moduleId]
                ? state.modules[action.moduleId].items
                : []),
              action.item,
            ],
          },
        },
      };
    case actionTypes.PREPPEND:
      return {
        ...state,
        modules: {
          ...state.modules,
          [action.moduleId]: {
            loading: false,
            items: [
              ...action.items,
              ...(state.modules[action.moduleId]
                ? state.modules[action.moduleId].items
                : []),
            ],
          },
        },
      };
    case actionTypes.UPDATE_ONE:
      return {
        ...state,
        modules: {
          ...state.modules,
          [action.moduleId]: {
            loading: false,
            items:
              (state.modules[action.moduleId]
                ? state.modules[action.moduleId].items
                : []
              ).map((item) =>
                item.id === action.item.id ? action.item : item
              )
            ,
          },
        },
      };
    case actionTypes.DESTROY:
      return {
        ...state,
        modules: {
          ...state.modules,
          [action.moduleId]: {
            loading: false,
            items: [
              (state.modules[action.moduleId]
                ? state.modules[action.moduleId].items
                : []
              ).filter((item) => item.id !== action.item.id),
            ],
          },
        },
      };
    case actionTypes.LOADING:
      return {
        ...state,
        modules: {
          ...state.modules,
          [action.moduleId]: {
            loading: true,
            items:
              state.modules[action.moduleId] &&
              state.modules[action.moduleId].items
                ? state.modules[action.moduleId].items
                : [],
          },
        },
      };
    default:
      return state;
  }
};
