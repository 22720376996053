import * as actionTypes from "./actionTypes";

export const update = (app) => ({
  type: actionTypes.UPDATE,
  app,
});

export const setSlug = (slug) => ({
  type: actionTypes.SET_SLUG,
  slug
});

const loading = () => ({
  type: actionTypes.LOADING
});

const setManifest = (manifest) => ({
  type: actionTypes.SET_MANIFEST,
  manifest
});

export const showAlert = (alert) => ({
  type: actionTypes.SHOW_ALERT,
  alert
});

export const hideAlert = () => ({
  type: actionTypes.HIDE_ALERT
});


