import React, { useState, useEffect, useContext } from "react";

import WidgetLoader from "../WidgetLoader";
import SurveyBlock from './components/SurveyBlock';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as surveyBlocksActionCreators from "../../data/surveyBlocks/actions";

import { FeathersContext } from "../../services/feathers/FeathersProvider";

import { useIntl, FormattedMessage } from "react-intl";

function SurveyBlocksWidget(props) {

  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);
  const { socket, themeColor } = props;

  const intl = useIntl();
  const lang = intl.locale;

  return (
    <div class="survey-blocks-wrap marginated">
      <div class="title">
        <span className={`icon-md-${props.module.icon}`}></span>
        <h3>{props.module.title && props.module.title[lang]}</h3>
      </div>
      {props.loading ? (
        <WidgetLoader themeColor={themeColor} />
      ) : <div>
          {props.module.votingBlocks.length === 0 ? (
            <div class="no-surveys">
              <p><FormattedMessage id="surveyBlocksWidget.noQuestions" /></p>
            </div>
          ) : props.module.votingBlocks
            .filter((sb) => sb.active)
            .sort((a, b) => a.position > b.position)
            .map((sb) => <SurveyBlock moduleId={props.module._id} surveyBlock={sb} loading={props.loading} />)}
      </div>
      }
    </div>
  );
}

export default connect(
  (state, ownProps) => ({
    manifest: state.services.app.manifest,
    loading: false,
    surveyBlocks: state.data.surveyBlocks.modules[ownProps.moduleId]
      ? state.data.surveyBlocks.modules[ownProps.moduleId].items
      : [],

  }),
  (dispatch) => ({
    actions: {
      surveyBlocks: bindActionCreators(surveyBlocksActionCreators, dispatch),
    },
  })
)(SurveyBlocksWidget);