import React, { useEffect, useState, useContext, Fragment } from "react";

import TopBar from "../../components/TopBar";
import { scaledImageUrl } from '../../components/ScaledImage'

import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../../services/app/actions";

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../services/feathers/FeathersProvider";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import ReactMarkdown from "react-markdown";
const htmlParser = require('react-markdown/plugins/html-parser')

const amplitude = require("amplitude-js");

const parse = htmlParser({
  isValidNode: (node) => node.type !== 'script',
  processingInstructions: [/* ... */]
})

function App(props) {
  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);
  const intl = useIntl();
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  useEffect(() => {
    amplitude.getInstance().logEvent("pageView", { name: "SignIn" });
  }, []);

  useEffect(() => {
    if (props.feathersAuthenticated) navigate(`/${props.slug}/`)
  }, [navigate, props.feathersAuthenticated, props.slug]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--theme-primary-color",
      props.manifest.themeColor
    );
    const components = hexToRgb(props.manifest.themeColor);
    if (components) {
      document.documentElement.style.setProperty(
        "--theme-primary-color-light",
        `rgba(${components.r},${components.g},${components.b},0.1)`
      );
      document.documentElement.style.setProperty(
        "--theme-primary-color-hover",
        `rgba(${components.r},${components.g},${components.b},0.85)`
      );
    }
  }, [props.manifest]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    feathersContext.authenticate(props.slug, email, password)
      .then(() => {
        //navigate(`/${props.slug}/`)
      })
      .catch((e) => {
        setSubmitting(false);
        console.log("Auth error", e);
        props.actions.app.showAlert({
          modal: true,
          title: intl.formatMessage({ id: "signIn.credentialAlertTitle" }),
          body: intl.formatMessage({ id: "signIn.credentialAlertBody" }),
          okText: intl.formatMessage({ id: "signIn.credentialAlertOk" })
        });
      });
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    if (email && email != '') {
      feathersContext.client.service("authManagement").create({
        action: 'sendResetPwd',
        value: {
          appId: props.slug,
          email: email
        },
        meta: {
          resetOrigin: 'virtualVenues',
          locale: 'es'
        }
      }).then(r => {
        props.actions.app.showAlert({
          modal: true,
          body: intl.formatMessage({ id: "signIn.forgotEmailSent" }),
          okText: intl.formatMessage({ id: "signIn.forgotEmailClose" })
        });
      }).catch(e => {
        props.actions.app.showAlert({
          modal: true,
          body: intl.formatMessage({ id: "signIn.forgotEmailSent" }),
          okText: intl.formatMessage({ id: "signIn.forgotEmailClose" })
        });
      })
    } else {
      props.actions.app.showAlert({
        modal: true,
        body: intl.formatMessage({ id: "signIn.forgotEmailRequired" }),
        okText: intl.formatMessage({ id: "signIn.forgotEmailClose" })
      });
    }
  };

  const { manifest, slug } = props;

  const lang = intl.locale;

  return (
    props.manifest.accessOptions.loginable && (
      <div
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column", backgroundColor: props.manifest.themeColor }}
      >
        <TopBar />
        <div className="empty-container" style={{  }}>
          <div className="login">
            <div
              className="logo"
              style={{
                backgroundImage: `url(${props.manifest.logo})`,
              }}
            ></div>
            <h2>
              <FormattedMessage id="signIn.title" />
            </h2>
            <h3>{manifest.title && manifest.title[lang]}</h3>
            <form>
              <div className="pill login-form">
                <input
                  autofocus
                  type="text"
                  placeholder={intl.formatMessage({ id: "signIn.email" })}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="password"
                  placeholder={intl.formatMessage({ id: "signIn.password" })}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <p className="help">
                <a
                  href="#"
                  onClick={forgotPassword}
                >
                  <FormattedMessage id="signIn.forgot" />
                </a>
              </p>
              {error && !submitting && (
                <div className="message-box show">{error}</div>
              )}

              <button
                disabled={submitting}
                onClick={handleSubmit}
                className="btn btn-block btn-lg"
              >
                {submitting
                  ? intl.formatMessage({ id: "signIn.submitting" })
                  : intl.formatMessage({ id: "signIn.submit" })}
              </button>
              {props.manifest.accessOptions.registerable && (
                <p className="help">
                  <FormattedMessage id="signIn.accountYet" />{" "}
                  {props.manifest.accessOptions.registerOverride ? (
                    <a href={props.manifest.accessOptions.registerURL[lang]}>
                      <FormattedMessage id="topBar.signUp" />
                    </a>
                  ) : (
                    <Link to={`/${props.slug}/signup`}>
                      <FormattedMessage id="signIn.signUp" />
                    </Link>
                  )}

                </p>
              )}
              {props.manifest.accessOptions.showLoginText &&
                <div className="login-form">
                  <p className="help" style={{ color: 'rgba(255,255,255,0.65)', textAlign: 'center', fontSize: 12 }}>
                    <ReactMarkdown children={props.manifest.accessOptions.loginText[lang]} htmlParser={parse} skipHtml={false} allowDangerousHtml />
                  </p>
                </div>
              }
            </form>
          </div>
        </div>
      </div>
    )
  );
}

export default connect(
  (state, ownProps) => ({
    manifest: state.services.app.manifest,
    slug: state.services.app.slug,
    feathersAuthenticated: state.services.app.feathersAuthenticated,
  }),
  (dispatch) => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch),
    },
  })
)(App);
