import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";

import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import auth from "@feathersjs/authentication-client";
import io from "socket.io-client";

import * as uuid from 'uuid';

import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../app/actions";
import * as appointmentsActionCreators from "../../data/appointments/actions";
import * as roomsActionCreators from "../../data/rooms/actions";
import * as usersActionCreators from "../../data/users/actions";
import * as meetingsActionCreators from "../../data/meetings/actions";
import * as conversationsActionCreators from "../../data/conversations/actions";
import * as messagesActionCreators from "../../data/messages/actions";
import * as questionsActionCreators from "../../data/questions/actions";

import { useMixPanel } from '../../services/mixpanel/MixpanelProvider';

import apiConfig from "../api/config";

const SERVICES = {
  appointments: {
    actions: appointmentsActionCreators,
  },
  rooms: {
    actions: roomsActionCreators,
  },
  users: {
    actions: usersActionCreators,
  },
  meetings: {
    actions: meetingsActionCreators,
  },
  conversations: {
    actions: conversationsActionCreators,
  },
  messages: {
    actions: messagesActionCreators,
  }
};


const FeathersContext = createContext(null);

export { FeathersContext };

const FeathersProvider = ({ slug, children, actions, ...props }) => {

  let socket;
  let client;
  const [feathersObject, setFeathersObject] = useState(null);
  const [manifestLoaded, setManifestLoaded] = useState(false);

  const dispatch = useDispatch();
  const { setMixpanelUser, trackMixpanelEvent } = useMixPanel()


  const processParams = useCallback((params) => {
    if (slug) {
      return {
        ...params,
        query: { ...(params.query ? params.query : {}), appId: slug },
      };
    } else {
      return params;
    }
  }, [slug]);

  const processData = useCallback((data) => {
    if (slug) {
      return { ...data, appId: slug };
    } else {
      return data;
    }
  }, [slug]);

  const myUniqueId = () => {
    const storedId = localStorage.getItem('uBelongDeviceId');
    if (!storedId) {
      const myId = uuid.v4();
      localStorage.setItem('uBelongDeviceId', myId);
      return myId;
    }
    return storedId;
  }

  useEffect(() => {

    socket = io(apiConfig.url, {
      transports: ['websocket'],
      upgrade: false,
      query: {
        platform: 'virtualVenues',
        slug
      }
    });
    client = feathers();
    client.configure(socketio(socket));

    client.configure(
      auth({
        storage: window.localStorage,
        path: "/authentication/users",
        storageKey: `ubelong_virtual_${slug}_jwt`,
      })
    );

    // Subscribe to real-time events
    client.service('meetings')
      .on('created', meeting => {
        console.log('Meeting created', meeting)
        //alert(JSON.stringify(meeting));
        //get("meetings", meeting._id).then(m => dispatch(SERVICES["meetings"].actions.append([m])));
        findAndUpdate('meetings', { query: { $sort: { date: 1 }, $limit: 50 } });
        //dispatch(SERVICES["meetings"].actions.append([meeting]));
      })
      .on('updated', meeting => {
        console.log('Meeting updated', meeting)
        dispatch(SERVICES["meetings"].actions.updateOne(meeting));
      })
      .on('patched', meeting => {
        console.log('Meeting patched', meeting)
        dispatch(SERVICES["meetings"].actions.updateOne(meeting));
      });

    client.service('messages')
      .on('created', message => {
        findConversations();
        // if (String(props.messages.currentConversation) === String(message.sender)) {
        //   actions.messages.preppend([message]);
        // } else {
        //   alert(JSON.stringify(props.messages));
        //   alert(`Not Current sender ${String(props.messages.currentConversation)} ${String(message.sender)}`);
        // }
        actions.messages.preppend([message]);
      });

    client.service('questions')
      .on('created', question => {
        //alert("Question");
        actions.questions.preppend(question.moduleId, [question]);
      });

    client.service('users')
      .on('patched', data => {
        if (data.currentDeviceId != myUniqueId()) {
          logOut();
        }
      });

    const _find = (serviceName, params = {}) =>
      client.service(serviceName).find(processParams(params));
    const get = (serviceName, id, params = {}) =>
      client.service(serviceName).get(id, params);
    const create = (serviceName, data, params = {}) =>
      client.service(serviceName).create(processData(data), params);
    const update = (serviceName, id, data, params = {}) =>
      client.service(serviceName).update(id, data, params);
    const patch = (serviceName, id, data, params = {}) =>
      client.service(serviceName).patch(id, data, params);
    const remove = (serviceName, id, params = {}) =>
      client.service(serviceName).remove(id, params);

    const find = (serviceName, params = {}) =>
      client
        .service(serviceName)
        .find(processParams(params));

    const findAndUpdate = (serviceName, params = {}) =>
      client
        .service(serviceName)
        .find(processParams(params))
        .then((r) => {
          console.log("DATA", r.data)
          if (r.data) dispatch(SERVICES[serviceName].actions.update(r.data, r.total));
          else dispatch(SERVICES[serviceName].actions.update(r, 0));
        });

    const findAndAppend = (serviceName, params = {}) =>
      client
        .service(serviceName)
        .find(processParams(params))
        .then((r) => {
          dispatch(SERVICES[serviceName].actions.append(r.data, r.total));
        });

    const createAndAppend = (serviceName, data, params = {}) => client
        .service(serviceName)
        .create(processData(data), params)
        .then((r) => {
          dispatch(SERVICES[serviceName].actions.append([r]));
        });

    const createAndPreppend = (serviceName, data, params = {}) => client
        .service(serviceName)
        .create(processData(data), params)
        .then((r) => {
          dispatch(SERVICES[serviceName].actions.preppend([r]));
        });

    const patchAndUpdate = (serviceName, id, data, params = {}) =>
      client
        .service(serviceName)
        .patch(id, data, params)
        .then((r) => {
          dispatch(SERVICES[serviceName].actions.updateOne(r));
        });

    const removeAndUpdate = (serviceName, id, params = {}) =>
      client
        .service(serviceName)
        .remove(id, params)
        .then((r) => {
          dispatch(SERVICES[serviceName].actions.destroy(r));
        });

    const loggedLoad = () => {
      findConversations();
      findAndUpdate('meetings', { query: { $sort: { date: 1 }, $limit: 50 } });
    };
    const commonLoad = () => {
      actions.appointments.loading();
      findAndUpdate('appointments', {});
      // Get all virtual rooms
      actions.rooms.loading();
      findAndUpdate("rooms", { query: { $sort: { position: 1 }, $limit: 50, } });
    };

    const authenticate = (appId, email, password) =>
      client
        .authenticate({
          strategy: "local",
          appId: appId,
          email: email,
          password: password,
          deviceId: myUniqueId()
        })
        .then((r) => {
          trackMixpanelEvent("loggedIn");
          setMixpanelUser(r.user);
          actions.app.update({ feathersAuthenticated: true, feathersUser: r.user });
          loggedLoad();
          commonLoad();

        })
        .catch((e) => {
          actions.app.update({ feathersAuthenticated: false, feathersUser: {} });
          throw e;
        });

    const logOut = () => {
      trackMixpanelEvent("loggedOut");
      client.logout();
      actions.app.update({ feathersAuthenticated: false, feathersUser: {} });
    }

    const updateUser = (userId, data) =>
      patch("users", userId, data).then((r) => {
        trackMixpanelEvent("updatedUser");
        setMixpanelUser(r);
        actions.app.update({ feathersUser: r });
      });

    const findConversations = (params = {}) => find('conversations', params).then( r => {
      actions.conversations.update(r.data, r.total, r.unread);
    });

    const loadMoreConversations = (params = {}) => find('conversations', params).then( r => {
      actions.conversations.update(r.data, r.total, r.unread);
    });

    const readConversation = (id) => {
      patch('conversations', id, { read: true }, {}).then( r => {
        actions.conversations.update(r.data, r.total, r.unread);
      });
    }

    socket.on("connect", (connection) => {
      trackMixpanelEvent("connected");
      actions.app.update({ feathersConnected: true });
      // Now that feathers is connected, i load the app manifest
      client
        .service("apps")
        .find({ query: { slug } })
        .then((result) => {
          if (result.data.length > 0) {

            actions.app.update({ manifest: result.data[0] });
          }


          setManifestLoaded(true);

        }).catch(e => {
          if (e.code === 401) window.location.reload();
        });
      // And now I check for authentication
      client
        .reAuthenticate()
        .then(async () => {
          actions.app.update({ feathersAuthenticated: true });
          // Retrieve user
          const r = await client.get("authentication");
          // alert(`Stored ${r.user.currentDeviceId}`);
          // alert(`Mine ${myUniqueId()}`);
          if (r.user.currentDeviceId != myUniqueId()) {
            logOut();
          } else {
            setMixpanelUser(r.user);
            actions.app.update({ feathersUser: r.user });
            loggedLoad();
          }
        })
        .catch(() => {
          actions.app.update({ feathersAuthenticated: false });
        }).finally( () => {
          // No matter the outcome of the reauth
          // Get all relevant appointments
          commonLoad();
        });
    });

    socket.on("disconnect", (connection) => {
      trackMixpanelEvent("disconnected");
      console.log("SOCKET DISCONNECYED");
      actions.app.update({ feathersConnected: false });
    });

    setFeathersObject({
      socket: socket,
      client: client,
      authenticate,
      logOut,
      updateUser,
      findConversations,
      loadMoreConversations,
      readConversation,
      get,
      find,
      findAndUpdate,
      findAndAppend,
      patch,
      patchAndUpdate,
      removeAndUpdate,
      create,
      createAndAppend,
      createAndPreppend
    });



  }, [slug]);

  if (!props.manifest._id) {
    return (<div>App not found.</div>);
  } else if (!window.WebSocket) {
    return (<div>No websockets compatibility.</div>);
  } else {
    return (
      <FeathersContext.Provider value={feathersObject}>
        {feathersObject && manifestLoaded ? children : null}
      </FeathersContext.Provider>
    );
  }



};

export default connect(
  (state, ownProps) => ({
    loading: state.services.app.loading,
    manifest: state.services.app.manifest,
    messages: state.data.messages,
  }),
  (dispatch) => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch),
      appointments: bindActionCreators(appointmentsActionCreators, dispatch),
      rooms: bindActionCreators(roomsActionCreators, dispatch),
      conversations: bindActionCreators(conversationsActionCreators, dispatch),
      messages: bindActionCreators(messagesActionCreators, dispatch),
      questions: bindActionCreators(questionsActionCreators, dispatch),
    },
  })
)(FeathersProvider);
