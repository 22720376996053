import React, { useEffect } from 'react';

import { useLocation } from "react-router-dom";

import ReactGA from 'react-ga';

export default props => {

  let location = useLocation();

  useEffect(() => {
    ReactGA.initialize('UA-171717775-3');
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  return props.children;
}