import React, { useEffect, useState, useContext } from 'react';

// OWN ROOT COMPONENTS
import Message from './components/Message';

// WRAPPERS AND CONTEXT CREATORS
import { FeathersContext } from "../../../../services/feathers/FeathersProvider";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as messagesActionCreators from "../../../../data/messages/actions";

// OTHER UTILS
import { useIntl, FormattedMessage } from "react-intl";

// STYLING
const styles = {};

const Component = ({sender, ...props}) => {
  // FEATHERS CONTEXT LOAD
  const feathersContext = useContext(FeathersContext);

  const intl = useIntl();

  const [message, setMessage] = useState('');
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    feathersContext.findAndUpdate("messages", { query: {
      $or: [
        {
          sender: sender._id,
          receiver: props.services.app.feathersUser._id
        },
        {
          receiver: sender._id,
          sender: props.services.app.feathersUser._id
        }
      ],
      $sort: { createdAt: -1 }
    } });
    setMessage('');
  }, [feathersContext, props.services.app.feathersUser._id, sender])

  useEffect(() => {
  }, [props.data.messages.items]);

  const handleSubmit = e => {
    e.preventDefault();

    feathersContext.create("messages", {
      sender: props.services.app.feathersUser._id,
      receiver: sender._id,
      text: message
    }).then( () => setMessage('') );
  };

  return (
    <div className="Chat">
      <div className="Header">
        <span className="icon-md-chat"></span>
        <h2 className="Title"><FormattedMessage id="networking.chatWith" /> {sender.firstName} {sender.lastName}</h2>
      </div>
      <div className="Body">
        <div className="BodyInner">
          {props.data.messages.items.slice(0).map( m => (
          <Message conversationSender={sender} message={m} mine={String(m.receiver) === String(sender._id)} themeColor={props.services.app.manifest.themeColor} />
        ))}
        </div>
      </div>
      <div className="Footer">
        <form class="questions-input-wrap pill" onSubmit={handleSubmit}>
          <input
            type="text"
            class={`questions-input ${
              focus || message.length > 0 ? "active" : null
            }`}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            placeholder={intl.formatMessage({ id: 'networking.messagePlaceholder' })}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
          <div class="questions-input-footer">
            <button
              type="submit"
              class={focus || message.length > 0 ? "active" : null}
            >
              <span class="icon icon-sm-send"></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    data: {
      messages: state.data.messages
    },
    services: {
      app: state.services.app,
    },
  }),
  (dispatch) => ({
    actions: {
      messages: bindActionCreators(messagesActionCreators, dispatch),
    },
  })
)(Component);