import * as actionTypes from "./actionTypes";

export const update = (items) => ({
  type: actionTypes.UPDATE,
  items,
});

export const append = (items) => ({
  type: actionTypes.APPEND,
  items
});

export const updateOne = (item) => ({
  type: actionTypes.UPDATE_ONE,
  item,
});

export const destroy = (item) => ({
  type: actionTypes.DESTROY,
  item,
});

export const loading = () => ({
  type: actionTypes.LOADING,
});
