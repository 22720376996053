import React from "react";

// OWN ROOT COMPONENTS
import { foldUnfold } from "../Curtain";

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";
import { getGrouped as getMeetingsGrouped } from '../../data/meetings/selectors';

// OTHER UTILS
import { NavLink } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";

// STYLING
const styles = {};

const Component = (props) => {
  return (
    <div className="leftBar">
      <NavLink className="item" to={`/${props.services.app.slug}`} end>
        <span class="icon-md-home"></span> <span className="title"><FormattedMessage id="leftBar.hall" /></span>
      </NavLink>
      {props.data.appointments.length > 0 && (
        <a href="#" class="item" onClick={(e) => foldUnfold(e, "agenda")}>
          <span class="icon-md-calendar"></span> <span className="title"><FormattedMessage id="leftBar.agenda" /></span>
        </a>
      )}
      {props.data.rooms.filter(r => !r.access.private).length > 0 && (
        <a href="#" class="item" onClick={(e) => foldUnfold(e, "rooms")}>
          <span class="icon-md-template"></span> <span className="title"><FormattedMessage id="leftBar.rooms" /></span>
        </a>
      )}
      {props.services.app.feathersUser._id ?
        <React.Fragment>
          {props.services.app.manifest.plugins.meetings?.active &&
            <NavLink className="item" to={`/${props.services.app.slug}/networking`}>
              {(props.data.pendingMeetings.length > 0 || props.data.unreadConversations > 0) &&
                <span className="Badge">{props.data.pendingMeetings.length + props.data.unreadConversations}</span>
              }
              <span class="icon-md-users"></span> <span className="title"><FormattedMessage id="leftBar.networking" /></span>
            </NavLink>
          }



        </React.Fragment>
      :
        null
      }

    </div>
  );
};

export default connect(
  (state) => ({
    data: {
      appointments: state.data.appointments.items,
      rooms: state.data.rooms.items,
      pendingMeetings: getMeetingsGrouped().pending,
      unreadConversations: state.data.conversations.unread
    },
    services: {
      app: state.services.app,
    },
  }),
  null
)(Component);
