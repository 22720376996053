import React from "react";

import { css } from "@emotion/core";
import BounceLoader from "react-spinners/BounceLoader";

const override = css`
  display: block;
  margin: 0 auto;
`;

export default (props) => (
  <BounceLoader css={override} size={220} color="#FBCA40" loading={true} />
);
